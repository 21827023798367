<template>
  <div class="editCss" @mousemove="mouseMove" @mouseup="mouseUp" @mousedown="mouseDown">
    <div class="pcBodyCss" @contextmenu.prevent="&quot;return&quot;;" v-if="ok">
      <!---------------- 顶栏 ---------------->
      <div class="pcHeadCss">
        <div class="pcHeadBackCss" title="返回" @click="goBack">
          <mu-icon size="28" value="reply" />
        </div>
        <div class="pcInfoCss">{{ dynalt.name }}</div>
        <div class="pcHeadRightCss">
          <div class="pcHeadIcoCss" target="_blank" @click="funcHandle('view')" id="previewYunZutai">
            <mu-icon size="14" value="visibility" style="float: left; margin-top: 22px; margin-right: 4px" />预览
          </div>
          <div class="pcHeadIcoCss" @click="funcHandle('save')">
            <mu-icon size="14" value="save" style="float: left; margin-top: 22px; margin-right: 4px" />保存
          </div>
          <div class="pcHeadIcoCss" @click="funcHandle('release')" v-show="!dynalt.release">
            <mu-icon size="14" value="near_me" style="float: left; margin-top: 22px; margin-right: 4px" />发布
          </div>
          <div class="pcHeadIcoCss" target="_blank" @click="funcHandle('qcfzsx')">
            <mu-icon size="14" style="float: left; margin-top: 22px; margin-right: 4px;" />清除竖线
          </div>
          <div class="pcHeadIcoCss" target="_blank" @click="funcHandle('fzsx')">
            <mu-icon size="14" style="float: left; margin-top: 22px; margin-right: 4px" />辅助竖线
          </div>
          <div class="pcHeadIcoCss" target="_blank" @click="funcHandle('qcfzxx')">
            <mu-icon size="14" style="float: left; margin-top: 22px; margin-right: 4px" />清除横线
          </div>
          <div class="pcHeadIcoCss" target="_blank" @click="funcHandle('fzxx')">
            <mu-icon size="14" style="float: left; margin-top: 22px; margin-right: 4px" />辅助横线
          </div>
        </div>
      </div>

      <!---------------- 功能栏 ---------------->
      
      <div class="pcLeftCss">
        <div class="leftNavBtnCss" @click="navHiddenEvent('left')" :style="
          leftNavHidden
            ? 'transition: transform 0.5s;'
            : ''
        ">
          <mu-icon size="24" value="view_carousel" color="grey50" v-show="leftNavHidden" />
          <mu-icon size="24" value="view_array" color="grey50" v-show="!leftNavHidden" />
        </div>
        <div class="pcLeftULCss">
          <div class="pcLeftULItemCss" v-for="(item, index) in funcUL" :key="index" :style="
            funcULCur == index ? 'color: #fff;background-color: #4c84ff;' : ''
          " @click="funcULClick(index)">
            <div>
              <mu-icon size="22" :value="item.icon" />
            </div>
            <p>{{ item.title }}</p>
          </div>
        </div>
        <div class="pcLeftRMCss">
          <div class="pcLeftULItemCss" style="padding: 10px 0; height: 60px; color: #fff">
            <div>
              <mu-icon size="26" value="keyboard" />
            </div>
            <p>快捷键</p>
          </div>
          <div class="pcLeftULItemCss" style="padding: 10px 0; height: 60px; color: #fff">
            <div>
              <mu-icon size="26" value="library_books" />
            </div>
            <p>文档</p>
          </div>
          <div class="pcLeftULItemCss" style="padding: 10px 0; height: 60px; color: #fff">
            <div>
              <mu-icon size="26" value="help" />
            </div>
            <p></p>
          </div>
        </div>
      </div>

      <!---------------- 左侧工具栏 ---------------->
      <div class="leftNavCss" :style="leftNavStyle">
        <transition name="fade">
          <div class="funcItemCss" v-show="funcULCur == 0">
            <div class="leftNavBaseCss" v-for="(item, index) in LNoption.base" :key="index"
              @mousedown="leftNavClick($event, index, 'base', { index, item })">
              <div>
                <mu-icon size="40" color="grey700" :value="index" />
              </div>
              <span>{{ item }}</span>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="funcItemCss" v-show="funcULCur == 1">
            <div v-for="(item, index) in LNoption.module" :key="index">
              <div class="funcItemTitleCss">
                <div></div>
                <span>{{ item.name }}</span>
                <div></div>
              </div>
              <div style="width: calc(100% + 10px)">
                <div class="leftNavModuleCss" v-for="(im, idx) in item.list" :key="idx"
                  @mousedown="leftNavClick($event, im.id, 'module', im)">
                  <img :src="im.img" draggable="false" />
                  <div>{{ im.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="funcItemCss" v-show="funcULCur == 2">
            <!-- 左侧数据 -->
            <div v-for="(item, index) in LNoption.dataValue" :key="index">
              <div class="funcItemTitleCss">
                <div></div>
                <span>{{ item.name }}</span>
                <div></div>
              </div>
              <div style="width: calc(100% + 10px)">
                <div class="leftNavModuleCss" v-for="(im, idx) in item.list" :key="idx"
                  @mousedown="leftNavClick($event, im.id, 'dataValue', im)">
                  <img :src="im.img" draggable="false" />
                  <div>{{ im.title }}</div>
                </div>
                <div style="clear:both" />
              </div>
            </div>

            <div class="funcItemTitleCss">
              <div></div>
              <span>便捷数据</span>
              <div></div>
            </div>
            <div class="streamLabelCss">
              <div class="streamSelectCss">
                <select v-model="streamData" @change="streamDataChange">
                  <option :value="streamData">
                    请选择
                  </option>
                  <option v-for="(item, index) in streamList" :key="index" :value="item">
                    {{ item.name }}
                  </option>
                </select>
                <input v-model="selectStreamDataInput" placeholder="请选择数据流" class="rightNavSelectInputCss" @change="selectSteamDataInputChange" />
              </div>
              <div class="labelSelectCss">
                <input v-model="selectLabelDataInput" placeholder="请输入标签名" class="labelSelectInputCss" @change="selectLabelDataInputChange" />
                <div class="lableListCss">
                  <div class="labelCss"
                    v-for="(item, index) in labelList" :key="index"
                    @mousedown="labelNavClick($event, item)">
                    {{item.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="funcItemCss" v-show="funcULCur == 3">
            <div style="width: calc(100% + 10px)" v-for="(item, index) in LNoption.material" :key="index">
              <div class="leftNavMaterialCss" @mousedown="leftNavClick($event, item, 'material', item)">
                <img :src="urlHandle(item)" draggable="false" />
              </div>
            </div>
            <div style="float: left; width: 100%; height: 20px; text-align: center">
              已无更多数据
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div class="funcItemCss" v-show="funcULCur == 4">
            <div v-for="(item, index) in LNoption.active" :key="index">
              <div class="funcItemTitleCss">
                <div></div>
                <span>{{ item.name }}</span>
                <div></div>
              </div>
              <div style="width: calc(100% + 10px)">
                <div class="leftNavActiveCss" v-for="(im, idx) in item.list" :key="idx"
                  @mousedown="leftNavClick($event, im.id, 'active', im)">
                  <img :src="im.img" draggable="false" />
                  <div>{{ im.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="funcItemCss" v-show="funcULCur == 5">
            <div style="height: calc(100% - 40px)">
              <div style="width: calc(100% + 10px)" v-for="(item, index) in LNoption.image" :key="index">
                <div class="leftNavImageCss" @mousedown="leftNavClick($event, item, 'image', item)">
                  <div>
                    <img :src="item" draggable="false" v-if="viewLock" />
                  </div>
                  <span>{{ item.split("/")[item.split("/").length - 1] }}</span>
                  <button @mousedown="leftNavImageDelete(item)">
                    <mu-icon size="16" color="#fff" value="delete" />
                  </button>
                  <button style="top:50px;left:25px;width:55px;height:32px;color:white;font-size:18px;z-index: 99"
                    @mousedown.stop="leftNavImageLook(item)">
                    查看
                  </button>
                </div>
              </div>
            </div>
            <div class="funcItemButtomCss" style="text-align: center">
              <label for="leftNavImage">本地上传</label>
              <input id="leftNavImage" v-show="false" type="file" @change="leftNavEdit($event, 'image')"
                accept=".gif, .png, .jpeg, .jpg, .svg" />
            </div>
          </div>
        </transition>
      </div>

      <!---------------- 编辑区 ---------------->
      <div class="pcConCss" v-if="Object.keys(dynalt).length != 0" id="editedId">
        <div class="editNavSideCss" :style="editNavStyle()" @mousedown="elementClick($event, -1)">
          <div v-if="editNavShow('outside')">
            <div class="editNavItemCss" :style="editNavItemStyle(index)" v-for="(item, index) in dynalt.config.canvas"
              :key="index" @click="editNav_Event('show', index)" @contextmenu.prevent="editNav_Event('delete', index)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <mu-fade-transition>
          <div class="pcEditCss" ref="pcEditRef">
            <mu-scale-transition>
              <div
                class="editCenterCss"
                v-if="centerShow"
                :style="editCenterStyle()"
                @mousedown="handleMouseDown"
              >
                <!-- 辅助线 start -->
                <div
                  class="yZhouFzxCss"
                  v-for="item in yZhouFzxList"
                  :key="item.index"
                  :style="yZhouFzxStyle(item)"
                >
                  <div class="yZhouButCss" :style="{'background-color': item.bkColor}" @click="clickYZhouFzx(item)" />
                </div>

                <div
                  class="xZhouFzxCss"
                  v-for="item in xZhouFzxList"
                  :key="item.index"
                  :style="xZhouFzxStyle(item)"
                >
                  <div class="xZhouButCss" :style="{'background-color': item.bkColor}" @click="clickXZhouFzx(item)" />
                </div>
                <!-- 辅助线 end -->
                <div
                  v-show="isShowSelectMask"
                  :style="{left: selectMaskLeft+'px', top: selectMaskTop+'px', width: selectMaskWidth+'px', height: selectMaskHeight+'px'}"
                  class="selectMaskCss"
                />
                <div :class="{
                  'editCenterGuideCss': true,
                  'guideEnableCss': RNoption.auxLine,
                }" @mousedown="elementClick($event, -1)"></div>
                <div class="editCenterExpansionCss"></div>
                <div class="editNavSideCss" :style="editNavStyle()" v-if="editNavShow('inside')">
                  <div class="editNavItemCss" :style="editNavItemStyle(index)" v-for="(item, index) in dynalt.config.canvas"
                    :key="index" @click="editNav_Event('show', index)"
                    @contextmenu.prevent="editNav_Event('delete', index)">
                    {{ item.name }}
                  </div>
                </div>
                <div class="editCenterItemCss" :class="{'elementBorderCss': item.isMultiple}" v-for="(item, index) in pCanvas.element" :key="index"
                  :style="elementBoxStyle(item.box, index)">
                  <div class="editCenterItemBodyCss" @mousedown="elementClick($event, index)">
                    <div class="editCenterBaseMaskCss"></div>
                    <!-- 基本-文本 -->
                    <div v-if="item.type == 'text_fields'" class="editCenterBaseCss" :style="elementStyle(item)">
                      {{ item.value }}
                    </div>
                    <!-- 基本-时间 -->
                    <div v-if="item.type == 'date_range'" class="editCenterBaseCss" :style="elementStyle(item)">
                      {{ item.value.local }}
                    </div>
                    <!-- 基本-超链接 -->
                    <a v-if="item.type == 'link'" class="editCenterBaseCss" :style="elementStyle(item) + 'cursor: pointer;'"
                      @click="link_Event(item.value.to)">{{
                        item.value.name
                      }}</a>
                    <!-- 基本-刷新 -->
                    <a v-if="item.type == 'replay'" class="editCenterBaseCss"
                      :style="elementStyle(item) + 'cursor: pointer;'" @click="replay_Event()">{{ item.value }}
                    </a>
                    <!-- 基本-切换画布 -->
                    <a v-if="item.type == 'switch_video'" class="editCenterBaseCss"
                      :style="elementStyle(item) + 'cursor: pointer;'" @click="switchvideo_Event(item.value.to)">{{
                        item.value.name
                      }}</a>
                    <!-- 基本-iframe -->
                    <iframe v-if="item.type == 'web'" class="editCenterBaseCss" :src="item.value" />
                    <!-- 基本-全屏 -->
                    <img v-if="item.type == 'fullscreen'" class="editCenterBaseCss"
                      :src="require('../assets/edit/fullscreen.svg')" />
                    <!-- 基本-截图 -->
                    <img v-if="item.type == 'camera'" class="editCenterBaseCss"
                      :src="require('../assets/edit/camera.svg')" />
                      <!-- 基本-横屏 -->
                    <img v-if="item.type == 'landscape_screen'" class="editCenterBaseCss"
                      :src="require('../assets/edit/landscape_screen.svg')" />
                    <!-- 图库 and 上传 -->
                    <img v-if="item.type == 'material' || item.type == 'image'" class="editCenterBaseCss"
                      :src="item.value" />
                    <!-- 交互  -->
                    <canvas v-if="item.type == 'active'" :id="item.style.id" :height="item.box.height"
                      :width="item.box.width"></canvas>
                    <!-- 组件-视频 -->
                    <div v-if="item.type == 'data-video'" class="editCenterBaseCss"
                      :style="{ width: item.box.width + 'px', height: item.box.height + 'px' }">
                      <iframe :style="{ width: item.box.width + 'px', height: item.box.height - 50 + 'px' }"
                        :src="'https://open.ys7.com/ezopen/h5/iframe?url=' + item.url + '&autoplay=' + item.autoplay + '&accessToken=' + item.AccessToken"
                        allowfullscreen />
                      <div>
                        <mu-button small color="primary" style="padding: 0 12px">播放</mu-button>
                      </div>
                    </div>
                    <!-- 组件-数据 -->
                    <div v-if="item.type == 'data-value'" class="editCenterBaseCss" :style="elementStyle(item)">
                      {{
                        dataShow(
                          realtime[item.value.label.labelID],
                          item.value.content,
                          realtime.update,
                          item.value.bit
                        )
                      }}
                    </div>
                    <!-- 组件-开关 -->
                    <div v-if="item.type == 'data-switch'" class="editCenterBaseCss">
                      <img v-show="
                        0 ==
                        switchShow(
                          realtime[item.value.label.labelID],
                          realtime.update
                        )
                      " :src="item.value.closeImg" class="editCenterBaseCss" @click="switch_Event(item, 1)" />
                      <img v-show="
                        1 ==
                        switchShow(
                          realtime[item.value.label.labelID],
                          realtime.update
                        )
                      " :src="item.value.openImg" class="editCenterBaseCss" @click="switch_Event(item, 0)" />
                      <img v-show="
                        -1 ==
                        switchShow(
                          realtime[item.value.label.labelID],
                          realtime.update
                        )
                      " :src="item.value.errorImg" class="editCenterBaseCss" />
                    </div>
                    <!-- 组件-控制 -->
                    <img v-if="item.type == 'data-control'" class="editCenterBaseCss" :src="item.value.img"
                      @click="control_Event(item)" />
                    <!-- 组件-照片 -->
                    <div v-if="item.type == 'data-photo'" class="editCenterBaseCss">
                      <img :src='dataPhoto_Handle(item)' class="editCenterBaseCss" />
                    </div>
                    <!-- 组件-音频 -->
                    <div v-if="item.type == 'data-audio'" class="editCenterBaseCss">
                      <img :src='dataAudio_Handle(item)' class="editCenterBaseCss" />
                    </div>
                    <!-- 组件-文本 -->
                    <div v-if="item.type == 'data-text'" class="editCenterBaseCss" :style="elementStyle(item)">
                      {{ dataText_Handle(item) }}
                    </div>
                    <!-- 组件-曲线图 -->
                    <div v-if="item.type == 'chart-curve'" class="editCenterBaseCss">
                      <div :id="item.value.echartid" style="width: 100%; height: 100%;" />
                      <!-- :id="im.value.echartid" -->
                    </div>

                    <!-- 组件-二维表 -->
                    <div v-if="item.type == 'table-two'" style="border: 1px solid rgba(128,214,255, 0.4)">
                      <Table :id="item.value.id" :width="item.box.width + 'px'" :height="item.box.height + 'px'"
                        :tableList="item.value.tableList" />
                    </div>
                  </div>
                  <div class="editCenterItemLockCss" v-if="item.box.lock">
                    <mu-icon size="12" value="lock" color="#666" />
                  </div>
                  <div v-show="elementCur == index && !item.box.lock">
                    <div class="editCenterResizableCss resizableNCss" @mousedown="resizableClick($event, 'n', 'n-resize')">
                    </div>
                    <div class="editCenterResizableCss resizableECss" @mousedown="resizableClick($event, 'e', 'e-resize')">
                    </div>
                    <div class="editCenterResizableCss resizableSCss" @mousedown="resizableClick($event, 's', 's-resize')">
                    </div>
                    <div class="editCenterResizableCss resizableWCss" @mousedown="resizableClick($event, 'w', 'w-resize')">
                    </div>
                    <div class="editCenterResizableCss resizableNeCss" @mousedown="resizableClick($event, 'ne', 'ne-resize')">
                    </div>
                    <div class="editCenterResizableCss resizableSeCss" @mousedown="resizableClick($event, 'se', 'se-resize')">
                    </div>
                    <div class="editCenterResizableCss resizableSwCss" @mousedown="resizableClick($event, 'sw', 'sw-resize')">
                    </div>
                    <div class="editCenterResizableCss resizableNwCss" @mousedown="resizableClick($event, 'nw', 'nw-resize')">
                    </div>
                    <div class="editCenterResizableRotateCss" @mousedown="resizableClick($event, 'rotate', 'pointer')">
                    </div>
                  </div>
                </div>
              </div>
            </mu-scale-transition>
          </div>
        </mu-fade-transition>
      </div>

      <!---------------- 右侧工具栏 ---------------->
      <div class="pcRightCss" :style="rightNavStyle">
        <div class="rightNavBtnCss" @click="navHiddenEvent('right')" :style="
          rightNavHidden
            ? 'transform: translateX(-36px);transition: transform 0.5s;'
            : ''
        ">
          <mu-icon size="24" value="view_carousel" color="grey50" v-show="rightNavHidden" />
          <mu-icon size="24" value="view_array" color="grey50" v-show="!rightNavHidden" />
        </div>
        <div v-if="selectedElement.length === 0" class="rightNavCss">
          <div class="rightNavHeadCss">
            <div :class="{ rightNavHeadA: true, rightNavHeadB: rightNavCur }" @click="rightNavSwitch(true)">
              基本设置
            </div>
            <div :class="{ rightNavHeadA: true, rightNavHeadB: !rightNavCur }" @click="rightNavSwitch(false)">
              高级设置
            </div>
          </div>
          <div class="rightNavBodyCss" v-if="Object.keys(dynalt).length != 0">
            <!-- - - - - -  基本设置 - - - - - -->
            <transition name="slide-left">
              <div class="rightNavViewCss" v-if="rightNavCur">
                <!----- 功能选择 ----->
                <div class="rightNavItemCss">
                  <div class="rightNavTextCss">功能选择</div>
                  <div class="rightNavItemListCss">
                    <div :class="{
                      'rightNavCheckboxCss': RNoption.editEnable,
                      'rightNavBoxCss': true,
                    }" style="background: none; float: left" @click="RNoptionEdit($event, 'editEnable', true)">
                      <mu-icon size="20" value="picture_in_picture_alt" color="#fff" />
                    </div>
                    <div :class="{
                      'rightNavCheckboxCss': !RNoption.editEnable,
                      'rightNavBoxCss': true,
                    }" style="background: none; float: left" @click="RNoptionEdit($event, 'editEnable', false)">
                      <mu-icon size="20" value="settings_overscan" color="#fff" />
                    </div>
                  </div>
                </div>
                <!----- 基础设置 ----->
                <div class="rightNavDetailsCss">
                  <div></div>
                  <span>基础设置</span>
                  <div></div>
                </div>
                <!-- 画布 -->
                <div v-if="elementCur < 0">
                  <div class="rightNavItemCss">
                    <div class="rightNavTextCss">辅助线</div>
                    <div class="rightNavBoxCss" style="
                                                              border: 1px solid #000;
                                                              width: 28px;
                                                              background: #fff;
                                                            " @click="
                                                              RNoptionEdit($event, 'auxLine', !RNoption.auxLine)
                                                            ">
                      <mu-icon size="20" value="done" color="blue" style="margin: 3px" v-show="RNoption.auxLine">
                      </mu-icon>
                    </div>
                    <div class="rightNavSeparateCss" />
                    <div class="rightNavTextCss">画布宽</div>
                    <div class="rightNavInputCss" style="width: 50%">
                      <input type="text" v-model="dynalt.config.board.width" />
                    </div>
                    <div class="rightNavTextCss">画布高</div>
                    <div class="rightNavInputCss" style="width: 50%">
                      <input type="text" v-model="dynalt.config.board.height" />
                    </div>
                    <div class="rightNavSeparateCss" />
                    <div class="rightNavTextCss">运行页面自适应</div>
                    <div class="rightNavSelectCss" style="width: 50%">
                      <select name id v-model="dynalt.config.board.adaptive">
                        <option :value="true">是</option>
                        <option :value="false">否</option>
                      </select>
                    </div>
                  </div>
                  <div class="rightNavDetailsCss">
                    <div></div>
                    <span>详细设置</span>
                    <div></div>
                  </div>
                  <div class="rightNavItemCss">
                    <div class="rightNavTextCss">背景色</div>
                    <div class="rightNavColorboxCss">
                      <div class="rightNavBoxCss" :style="
                        'border: 1px solid #000;background:' +
                        dynalt.config.canvas[canvasCur].background
                      " @click="RNoptionEdit($event, 'canvas-background', canvasCur)"> </div>
                    </div>
                  </div>
                </div>
                <!-- 组件 -->
                <div v-if="elementCur >= 0">
                  <div v-if="pElement.box.lock">
                    <div class="rightNavItemCss">
                      <div class="rightNavButtonCss">
                        <mu-button small color="primary" style="padding: 0 12px"
                          @click="RNoptionEdit($event, 'box-lock', false)">取消锁定</mu-button>
                      </div>
                    </div>
                  </div>
                  <div v-if="!pElement.box.lock">
                    <!-- 基本-文本 -->
                    <div v-if="pElement.type == 'text_fields'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">修改文本</div>
                        <div class="rightNavTextareaCss">
                          <textarea v-model="pElement.value"></textarea>
                        </div>
                      </div>
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">文本设置</div>
                        <div class="rightNavManyboxCss">
                          <select style="width: 25%" v-for="(item, index) in textFields" :key="index"
                            v-model="pElement.style[index]">
                            <option :value="im.val" v-for="(im, idx) in item" :key="idx">
                              {{ im.key }}
                            </option>
                          </select>
                        </div>
                        <div class="rightNavTextCss">文本样式</div>
                        <div class="rightNavManyboxCss" style="font-family: 'wryh'">
                          <button style="color: #e53232" @click="RNoptionEdit($event, 'style-color')">
                            A
                          </button>
                          <button style="font-weight: bold" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontWeight',
                              pElement.style.fontWeight == 'normal'
                                ? 'bold'
                                : 'normal'
                            )
                          ">
                            B
                          </button>
                          <button style="font-style: italic" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontStyle',
                              pElement.style.fontStyle == 'normal'
                                ? 'italic'
                                : 'normal'
                            )
                          ">
                            I
                          </button>
                          <button style="text-decoration: underline" @click="
                            RNoptionEdit(
                              $event,
                              'style-textDecoration',
                              pElement.style.textDecoration == 'none'
                                ? 'underline'
                                : 'none'
                            )
                          ">
                            U
                          </button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 基本-时间 -->
                    <div v-if="pElement.type == 'date_range'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">文本设置</div>
                        <div class="rightNavManyboxCss">
                          <select style="width: 25%" v-for="(item, index) in textFields" :key="index"
                            v-model="pElement.style[index]">
                            <option :value="im.val" v-for="(im, idx) in item" :key="idx">
                              {{ im.key }}
                            </option>
                          </select>
                        </div>
                        <div class="rightNavTextCss">文本样式</div>
                        <div class="rightNavManyboxCss" style="font-family: 'wryh'">
                          <button style="color: #e53232" @click="RNoptionEdit($event, 'style-color')">
                            A
                          </button>
                          <button style="font-weight: bold" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontWeight',
                              pElement.style.fontWeight == 'normal'
                                ? 'bold'
                                : 'normal'
                            )
                          ">
                            B
                          </button>
                          <button style="font-style: italic" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontStyle',
                              pElement.style.fontStyle == 'normal'
                                ? 'italic'
                                : 'normal'
                            )
                          ">
                            I
                          </button>
                          <button style="text-decoration: underline" @click="
                            RNoptionEdit(
                              $event,
                              'style-textDecoration',
                              pElement.style.textDecoration == 'none'
                                ? 'underline'
                                : 'none'
                            )
                          ">
                            U
                          </button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 基本-超链接 -->
                    <div v-if="pElement.type == 'link'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">修改文本</div>
                        <div class="rightNavTextareaCss">
                          <textarea v-model="pElement.value.name"></textarea>
                        </div>
                      </div>
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">自定义链接</div>
                        <div class="rightNavInputCss" style="width: 100%">
                          <input type="text" v-model="pElement.value.to" />
                        </div>
                        <div class="rightNavButtonCss" style="margin-top: 10px">
                          <a :href="pElement.value.to" style="color: #fff" target="_blank">
                            <mu-button small color="primary" style="padding: 0 12px">测试链接</mu-button>
                          </a>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">文本设置</div>
                        <div class="rightNavManyboxCss">
                          <select style="width: 25%" v-for="(item, index) in textFields" :key="index"
                            v-model="pElement.style[index]">
                            <option :value="im.val" v-for="(im, idx) in item" :key="idx">
                              {{ im.key }}
                            </option>
                          </select>
                        </div>
                        <div class="rightNavTextCss">文本样式</div>
                        <div class="rightNavManyboxCss" style="font-family: 'wryh'">
                          <button style="color: #e53232" @click="RNoptionEdit($event, 'style-color')">
                            A
                          </button>
                          <button style="font-weight: bold" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontWeight',
                              pElement.style.fontWeight == 'normal'
                                ? 'bold'
                                : 'normal'
                            )
                          ">
                            B
                          </button>
                          <button style="font-style: italic" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontStyle',
                              pElement.style.fontStyle == 'normal'
                                ? 'italic'
                                : 'normal'
                            )
                          ">
                            I
                          </button>
                          <button style="text-decoration: underline" @click="
                            RNoptionEdit(
                              $event,
                              'style-textDecoration',
                              pElement.style.textDecoration == 'none'
                                ? 'underline'
                                : 'none'
                            )
                          ">
                            U
                          </button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 基本-刷新 -->
                    <div v-if="pElement.type == 'replay'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">修改文本</div>
                        <div class="rightNavTextareaCss">
                          <textarea v-model="pElement.value"></textarea>
                        </div>
                      </div>
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">文本设置</div>
                        <div class="rightNavManyboxCss">
                          <select style="width: 25%" v-for="(item, index) in textFields" :key="index"
                            v-model="pElement.style[index]">
                            <option :value="im.val" v-for="(im, idx) in item" :key="idx">
                              {{ im.key }}
                            </option>
                          </select>
                        </div>
                        <div class="rightNavTextCss">文本样式</div>
                        <div class="rightNavManyboxCss" style="font-family: 'wryh'">
                          <button style="color: #e53232" @click="RNoptionEdit($event, 'style-color')">
                            A
                          </button>
                          <button style="font-weight: bold" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontWeight',
                              pElement.style.fontWeight == 'normal'
                                ? 'bold'
                                : 'normal'
                            )
                          ">
                            B
                          </button>
                          <button style="font-style: italic" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontStyle',
                              pElement.style.fontStyle == 'normal'
                                ? 'italic'
                                : 'normal'
                            )
                          ">
                            I
                          </button>
                          <button style="text-decoration: underline" @click="
                            RNoptionEdit(
                              $event,
                              'style-textDecoration',
                              pElement.style.textDecoration == 'none'
                                ? 'underline'
                                : 'none'
                            )
                          ">
                            U
                          </button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 基本-切换画布 -->
                    <div v-if="pElement.type == 'switch_video'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">修改文本</div>
                        <div class="rightNavTextareaCss">
                          <textarea v-model="pElement.value.name"></textarea>
                        </div>
                        <div class="rightNavTextCss">选择画布</div>
                        <div class="rightNavSelectCss" style="width: 100%">
                          <select v-model="pElement.value.to">
                            <option :value="-1">请选择画布</option>
                            <option v-show="dynalt.config.canvas.length > 1" v-for="(item, index) in dynalt.config.canvas"
                              :key="index" :value="index">
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">文本设置</div>
                        <div class="rightNavManyboxCss">
                          <select style="width: 25%" v-for="(item, index) in textFields" :key="index"
                            v-model="pElement.style[index]">
                            <option :value="im.val" v-for="(im, idx) in item" :key="idx">
                              {{ im.key }}
                            </option>
                          </select>
                        </div>
                        <div class="rightNavTextCss">文本样式</div>
                        <div class="rightNavManyboxCss" style="font-family: 'wryh'">
                          <button style="color: #e53232" @click="RNoptionEdit($event, 'style-color')">
                            A
                          </button>
                          <button style="font-weight: bold" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontWeight',
                              pElement.style.fontWeight == 'normal'
                                ? 'bold'
                                : 'normal'
                            )
                          ">
                            B
                          </button>
                          <button style="font-style: italic" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontStyle',
                              pElement.style.fontStyle == 'normal'
                                ? 'italic'
                                : 'normal'
                            )
                          ">
                            I
                          </button>
                          <button style="text-decoration: underline" @click="
                            RNoptionEdit(
                              $event,
                              'style-textDecoration',
                              pElement.style.textDecoration == 'none'
                                ? 'underline'
                                : 'none'
                            )
                          ">
                            U
                          </button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 基本-iframe -->
                    <div v-if="pElement.type == 'web'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">自定义链接</div>
                        <div class="rightNavInputCss" style="width: 100%">
                          <input type="text" v-model="pElement.value" />
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                      </div>
                    </div>
                    <!-- 基本-全屏切换 -->
                    <div v-if="pElement.type == 'camera'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                      </div>
                    </div>
                    <!-- 基本-截图 -->
                    <div v-if="pElement.type == 'fullscreen'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                      </div>
                    </div>
                    <!-- 基本-横屏 -->
                    <div v-if="pElement.type == 'landscape_screen'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                      </div>
                    </div>
                    <!-- 图库 -->
                    <div v-if="pElement.type == 'material'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                      </div>
                    </div>
                    <!-- 上传 -->
                    <div v-if="pElement.type == 'image'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                      </div>
                    </div>
                    <!-- 交互 -->
                    <div v-if="pElement.type == 'active'">
                      <div name="管道" v-if="pElement.style.type == 'active-pipe'">
                        <div class="rightNavItemCss">
                          <div class="rightNavTextCss">液体颜色</div>
                          <div class="rightNavColorboxCss">
                            <div class="rightNavBoxCss" :style="
                              'border: 1px solid #000;background:' +
                              pElement.style.color
                            " @click="RNoptionEdit($event, 'style-color')"></div>
                          </div>
                          <div class="rightNavSeparateCss" />
                          <div class="rightNavButtonCss">
                            <mu-button small color="primary" style="padding: 0 12px"
                              @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                          </div>
                        </div>
                      </div>
                      <div name="水池" v-if="pElement.style.type == 'active-pool'">
                        <div class="rightNavItemCss">
                          <div class="rightNavTextCss">液体颜色</div>
                          <div class="rightNavColorboxCss">
                            <div class="rightNavBoxCss" :style="
                              'border: 1px solid #000;background:' +
                              pElement.style.waveColor
                            " @click="RNoptionEdit($event, 'style-waveColor')"></div>
                          </div>
                          <div class="rightNavTextCss">字体颜色</div>
                          <div class="rightNavColorboxCss">
                            <div class="rightNavBoxCss" :style="
                              'border: 1px solid #000;background:' +
                              pElement.style.color
                            " @click="RNoptionEdit($event, 'style-color')"></div>
                          </div>
                          <div class="rightNavTextCss">字体大小</div>
                          <div class="rightNavInputCss" style="width: 50%">
                            <input type="text" v-model="pElement.style.fontSize" />
                          </div>
                          <div class="rightNavSeparateCss" />
                          <div class="rightNavButtonCss">
                            <mu-button small color="primary" style="padding: 0 12px"
                              @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-视频 -->
                    <div v-if="pElement.type == 'data-video'">
                      <div class="rightNavItemCss">
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-数据 -->
                    <div v-if="pElement.type == 'data-value'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">文本设置</div>
                        <div class="rightNavManyboxCss">
                          <select style="width: 25%" v-for="(item, index) in textFields" :key="index"
                            v-model="pElement.style[index]">
                            <option :value="im.val" v-for="(im, idx) in item" :key="idx">
                              {{ im.key }}
                            </option>
                          </select>
                        </div>
                        <div class="rightNavTextCss">文本样式</div>
                        <div class="rightNavManyboxCss" style="font-family: 'wryh'">
                          <button style="color: #e53232" @click="RNoptionEdit($event, 'style-color')">
                            A
                          </button>
                          <button style="font-weight: bold" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontWeight',
                              pElement.style.fontWeight == 'normal'
                                ? 'bold'
                                : 'normal'
                            )
                          ">
                            B
                          </button>
                          <button style="font-style: italic" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontStyle',
                              pElement.style.fontStyle == 'normal'
                                ? 'italic'
                                : 'normal'
                            )
                          ">
                            I
                          </button>
                          <button style="text-decoration: underline" @click="
                            RNoptionEdit(
                              $event,
                              'style-textDecoration',
                              pElement.style.textDecoration == 'none'
                                ? 'underline'
                                : 'none'
                            )
                          ">
                            U
                          </button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-开关 -->
                    <div v-if="pElement.type == 'data-switch'">
                      <div class="rightNavItemCss">
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-控制 -->
                    <div v-if="pElement.type == 'data-control'">
                      <div class="rightNavItemCss">
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-照片 -->
                    <div v-if="pElement.type == 'data-photo'">
                      <div class="rightNavItemCss">
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-音频 -->
                    <div v-if="pElement.type == 'data-audio'">
                      <div class="rightNavItemCss">
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-文本 -->
                    <div v-if="pElement.type == 'data-text'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">文本设置</div>
                        <div class="rightNavManyboxCss">
                          <select style="width: 25%" v-for="(item, index) in textFields" :key="index"
                            v-model="pElement.style[index]">
                            <option :value="im.val" v-for="(im, idx) in item" :key="idx">
                              {{ im.key }}
                            </option>
                          </select>
                        </div>
                        <div class="rightNavTextCss">文本样式</div>
                        <div class="rightNavManyboxCss" style="font-family: 'wryh'">
                          <button style="color: #e53232" @click="RNoptionEdit($event, 'style-color')">
                            A
                          </button>
                          <button style="font-weight: bold" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontWeight',
                              pElement.style.fontWeight == 'normal'
                                ? 'bold'
                                : 'normal'
                            )
                          ">
                            B
                          </button>
                          <button style="font-style: italic" @click="
                            RNoptionEdit(
                              $event,
                              'style-fontStyle',
                              pElement.style.fontStyle == 'normal'
                                ? 'italic'
                                : 'normal'
                            )
                          ">
                            I
                          </button>
                          <button style="text-decoration: underline" @click="
                            RNoptionEdit(
                              $event,
                              'style-textDecoration',
                              pElement.style.textDecoration == 'none'
                                ? 'underline'
                                : 'none'
                            )
                          ">
                            U
                          </button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-曲线图 -->
                    <div v-if="pElement.type == 'chart-curve'">
                      <div class="rightNavItemCss">
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">背景色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            pElement.box.background
                          " @click="RNoptionEdit($event, 'box-background')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 组件-二维表 -->
                    <div v-if="pElement.type == 'table-two'">
                      <div class="rightNavItemCss">
                        <div class="rightNavButtonCss">
                          <mu-button small color="primary" style="padding: 0 12px"
                            @click="RNoptionEdit($event, 'box-lock', true)">锁定</mu-button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!----- 显示设置 ----->
                <div v-if="elementCur >= 0 && !pElement.box.lock">
                  <div class="rightNavDetailsCss">
                    <div></div>
                    <span>显示设置</span>
                    <div></div>
                  </div>
                  <div class="rightNavItemCss">
                    <div class="rightNavTextCss">大小</div>
                    <div class="rightNavItemListCss">
                      <span>宽</span>
                      <div class="rightNavInputCss" style="width: 60px">
                        <input type="text" v-model="pElement.box.width" />
                      </div>
                      <span>高</span>
                      <div class="rightNavInputCss" style="width: 60px">
                        <input type="text" v-model="pElement.box.height" />
                      </div>
                    </div>
                    <div class="rightNavSeparateCss" />
                    <div class="rightNavTextCss">位置</div>
                    <div class="rightNavItemListCss">
                      <span>X</span>
                      <div class="rightNavInputCss" style="width: 60px">
                        <input type="text" v-model="pElement.box.left" />
                      </div>
                      <span>Y</span>
                      <div class="rightNavInputCss" style="width: 60px">
                        <input type="text" v-model="pElement.box.top" />
                      </div>
                    </div>
                    <div class="rightNavSeparateCss" />
                    <div class="rightNavTextCss">旋转</div>
                    <div style="height: 30px; width: 100%; display: flex">
                      <mu-slider style="flex: 1; margin: 0 16px 0 0; height: 30px" v-model="pElement.box.rotate"
                        :max="359" :step="1"></mu-slider>
                      <div class="rightNavInputCss" style="width: 60px">
                        <input type="number" :min="0" :max="359" v-model="pElement.box.rotate" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <!-- - - - - -  高级设置 - - - - - -->
            <transition name=" slide-right">
              <div class="rightNavViewCss" v-if="!rightNavCur">
                <div v-if="elementCur < 0">
                  <div class="rightNavItemCss">
                    <div class="rightNavTextCss">添加画布</div>
                    <div class="rightNavInputCss" style="width: 50%; margin-right: 10px">
                      <input type="text" v-bind:value="
                        '已添加' + dynalt.config.canvas.length + '个'
                      " disabled />
                    </div>
                    <div class="rightNavButtonCss">
                      <mu-button small color="primary" @click="RNoptionEdit($event, 'canvas', -1)">添加</mu-button>
                    </div>
                    <div class="rightNavTextCss">隐藏画布栏目</div>
                    <div class="rightNavBoxCss" style="
                                                              border: 1px solid #000;
                                                              width: 28px;
                                                              background: #fff;
                                                            " @click="RNoptionEdit($event, 'nav-enable')">
                      <mu-icon size="20" value="done" color="blue" style="margin: 3px"
                        v-show="dynalt.config.nav.enable" />
                    </div>
                  </div>
                  <div class="rightNavDetailsCss">
                    <div></div>
                    <span>导航条设置</span>
                    <div></div>
                  </div>
                  <div class="rightNavItemCss" v-if="dynalt.config.canvas.length > 0 && canvasCur >= 0">
                    <div class="rightNavTextCss">修改导航条名称</div>
                    <div class="rightNavInputCss" style="width: 50%; margin-right: 10px">
                      <input type="text" v-model="dynalt.config.canvas[canvasCur].name" />
                    </div>
                    <div class="rightNavSeparateCss" />
                    <div class="rightNavTextCss">导航条浮动</div>
                    <div class="rightNavSelectCss" style="width: 50%">
                      <select name id v-model="dynalt.config.nav.float">
                        <option value="outside">相对于页面</option>
                        <option value="inside">相对于画布</option>
                      </select>
                    </div>
                    <div class="rightNavTextCss">导航条位置</div>
                    <div class="rightNavSelectCss" style="width: 50%">
                      <select name id v-model="dynalt.config.nav.position">
                        <option value="top">上</option>
                        <option value="bottom">下</option>
                        <option value="left">左</option>
                        <option value="right">右</option>
                      </select>
                    </div>
                    <div class="rightNavSeparateCss" />
                    <div class="rightNavTextCss">导航条背景颜色</div>
                    <div class="rightNavColorboxCss">
                      <div class="rightNavBoxCss" :style="
                        'border: 1px solid #000;background:' +
                        dynalt.config.nav.background
                      " @click="RNoptionEdit($event, 'nav-background')"></div>
                    </div>
                    <div class="rightNavTextCss">导航条文字颜色</div>
                    <div class="rightNavColorboxCss">
                      <div class="rightNavBoxCss" :style="
                        'border: 1px solid #000;background:' +
                        dynalt.config.nav.color
                      " @click="RNoptionEdit($event, 'nav-color')"></div>
                    </div>
                    <div class="rightNavTextCss">导航切换颜色</div>
                    <div class="rightNavColorboxCss">
                      <div class="rightNavBoxCss" :style="
                        'border: 1px solid #000;background:' +
                        dynalt.config.nav.checkColor
                      " @click="RNoptionEdit($event, 'nav-checkColor')"></div>
                    </div>
                    <div class="rightNavSeparateCss" />
                    <div class="rightNavTextCss">画布切换特效</div>
                    <div class="rightNavSelectCss" style="width: 50%">
                      <select name id v-model="dynalt.config.nav.transition">
                        <option value="none">无</option>
                        <option value="fade">淡入淡出</option>
                        <option value="slide">滑入滑出</option>
                        <option value="expand">展开折叠</option>
                        <option value="bounce">缩放</option>
                      </select>
                    </div>
                    <div class="rightNavSeparateCss" />
                  </div>
                </div>
                <div v-if="elementCur >= 0">
                  <!-- 基本-文本 -->
                  <div v-if="pElement.type == 'text_fields'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">是否同步标题</div>
                      <div class="rightNavSelectCssInputCss">
                        <mu-switch v-model="pElement.isSyncTitle" @change="isSyncTitleChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">请选择使用的字体模板</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.fontTemplId" @change="fontTemplIdChange">
                          <option value="">
                            不使用字体模板
                          </option>
                          <option v-for="(item, index) in fontTemplList" :key="index" :value="item.id">
                            {{ item.fontTemplName }}
                          </option>
                        </select>
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">是设为字体模板</div>
                      <div class="rightNavSelectCssInputCss">
                        <mu-switch v-model="pElement.isFontTempl" @change="isFontTemplChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div v-if="pElement.isFontTempl">
                        <div class="rightNavTextCss">字体模板名称</div>
                        <div class="rightNavSelectCssInputCss">
                          <input style="width: 150px" v-model="pElement.fontTemplName" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 交互 -->
                  <div v-if="pElement.type == 'active'">
                    <div name="管道" v-if="pElement.style.type == 'active-pipe'">
                      <div class="rightNavTextCss">数据流</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.stream" @change="streamHandle">
                          <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in streams" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                      </div>
                      <div class="rightNavTextCss">标签</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.label" @change="labelHandle">
                          <option :value="pElement.value.label" v-if="pElement.value.label.labelID == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in label[
                            pElement.value.stream['api-key'] + '_input'
                          ]" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectLabelInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">刷新频率</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.cycle" />
                      </div>
                      <span class="rightNavSpanCss">秒</span>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">启用流动控制</div>
                      <div class="rightNavSelectCss" style="width: 80px">
                        <select v-model="pElement.value.enable" @change="streamHandle">
                          <option :value="false">关闭</option>
                          <option :value="true">启用</option>
                        </select>
                      </div>
                      <div class="rightNavTextCss">反向流动</div>
                      <div class="rightNavSelectCssInputCss">
                        <select v-model="pElement.value.positive.way" @change="labelHandle">
                          <option value=">">大于</option>
                          <option value="<">小于</option>
                          <option value="=">等于</option>
                          <option value=">=">大于或等于</option>
                          <option value="<=">小于或等于</option>
                        </select>
                        <input type="text" v-model="pElement.value.positive.val" />
                      </div>
                      <div class="rightNavTextCss">正向流动</div>
                      <div class="rightNavSelectCssInputCss">
                        <select v-model="pElement.value.reverse.way" @change="labelHandle">
                          <option value=">">大于</option>
                          <option value="<">小于</option>
                          <option value="=">等于</option>
                          <option value=">=">大于或等于</option>
                          <option value="<=">小于或等于</option>
                        </select>
                        <input type="text" v-model="pElement.value.reverse.val" />
                      </div>
                    </div>
                    <div name="水池" v-if="pElement.style.type == 'active-pool'">
                      <div class="rightNavItemCss">
                        <div class="rightNavTextCss">数据流</div>
                        <div class="rightNavSelectCss">
                          <select v-model="pElement.value.stream" @change="streamHandle">
                            <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                              请选择
                            </option>
                            <option v-for="(item, index) in streams" :key="index" :value="item">
                              {{ item.name }}
                            </option>
                          </select>
                          <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                        </div>
                        <div class="rightNavTextCss">标签</div>
                        <div class="rightNavSelectCss">
                          <select v-model="pElement.value.label" @change="labelHandle">
                            <option :value="pElement.value.label" v-if="pElement.value.label.labelID == ''">
                              请选择
                            </option>
                            <option v-for="(item, index) in label[
                              pElement.value.stream['api-key'] + '_input'
                            ]" :key="index" :value="item">
                              {{ item.name }}
                            </option>
                          </select>
                          <input v-model="selectLabelInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange" />
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">刷新频率</div>
                        <div class="rightNavInputCss" style="width: 50%">
                          <input type="text" v-model="pElement.value.cycle" />
                        </div>
                        <span class="rightNavSpanCss">秒</span>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">最大值（峰值）</div>
                        <div class="rightNavInputCss" style="width: 50%">
                          <input type="text" v-model="pElement.value.max" />
                        </div>
                        <div class="rightNavTextCss">最小值（谷值）</div>
                        <div class="rightNavInputCss" style="width: 50%">
                          <input type="text" v-model="pElement.value.min" />
                        </div>
                        <div class="rightNavSeparateCss" />
                        <div class="rightNavTextCss">显示百分比</div>
                        <div class="rightNavSelectCss" style="width: 50%">
                          <select v-model="pElement.value.show">
                            <option :value="true">是</option>
                            <option :value="false">否</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 组件-视频 -->
                  <div v-if="pElement.type == 'data-video'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">视频接入方式</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.videoType">
                          <option v-for="(item, index) in videoList" :key="index" :value="item.key">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">地址</div>
                      <div class="rightNavInputCss" style="width: 100%">
                        <input type="text" v-model="pElement.value.url" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">AppKey</div>
                      <div class="rightNavInputCss" style="width: 100%">
                        <input type="text" v-model="pElement.value.AppKey" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">Secret</div>
                      <div class="rightNavInputCss" style="width: 100%">
                        <input type="text" v-model="pElement.value.Secret" />
                      </div>
                    </div>
                  </div>
                  <!-- 组件-数据 -->
                  <div v-if="pElement.type == 'data-value'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">数据流</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.stream" @change="streamHandle">
                          <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in streams" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                      </div>
                      <div class="rightNavTextCss">标签</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.label" @change="labelHandle">
                          <option :value="pElement.value.label" v-if="pElement.value.label.labelID == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in label[
                            pElement.value.stream['api-key'] + '_input'
                          ]" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectLabelInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">刷新频率</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.cycle" />
                      </div>
                      <span class="rightNavSpanCss">秒</span>

                      <div class="rightNavTextCss">小数位数</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.bit" />
                      </div>
                      <div class="rightNavTextCss">显示内容</div>
                      <div class="rightNavSelectCss" style="width: 50%">
                        <select name id v-model="pElement.value.content">
                          <option value="value">数值</option>
                          <option value="time">更新时间</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- 组件-开关 -->
                  <div v-if="pElement.type == 'data-switch'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">数据流</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.stream" @change="streamHandle">
                          <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in streams" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                      </div>
                      <div class="rightNavTextCss">标签</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.label" @change="labelHandle">
                          <option :value="pElement.value.label" v-if="pElement.value.label.labelID == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in label[
                            pElement.value.stream['api-key'] + '_input'
                          ]" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectLabelInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">刷新频率</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.cycle" />
                      </div>
                      <span class="rightNavSpanCss">秒</span>

                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">自定义图片（开）</div>
                      <div style="height: 60px; width: 100%; display: flex">
                        <div class="rightNavSelectCss" style="
                                                                  width: 50%;
                                                                  display: inline-block;
                                                                  margin-top: 15px;
                                                                ">
                          <select v-model="pElement.value.openImg">
                            <option :value="require('../assets/open.svg')">
                              默认
                            </option>
                            <option v-for="(item, index) in LNoption.image" :key="index" :value="item">
                              {{ item.split("/")[item.split("/").length - 1] }}
                            </option>
                          </select>
                        </div>
                        <img :src="pElement.value.openImg" style="height: 60px; margin-left: 20px" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">自定义图片（关）</div>
                      <div style="height: 60px; width: 100%; display: flex">
                        <div class="rightNavSelectCss" style="
                                                                  width: 50%;
                                                                  display: inline-block;
                                                                  margin-top: 15px;
                                                                ">
                          <select v-model="pElement.value.closeImg">
                            <option :value="require('../assets/close.svg')">
                              默认
                            </option>
                            <option v-for="(item, index) in LNoption.image" :key="index" :value="item">
                              {{ item.split("/")[item.split("/").length - 1] }}
                            </option>
                          </select>
                        </div>
                        <img :src="pElement.value.closeImg" style="height: 60px; margin-left: 20px" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">自定义图片（异常）</div>
                      <div style="height: 60px; width: 100%; display: flex">
                        <div class="rightNavSelectCss" style="
                                                                  width: 50%;
                                                                  display: inline-block;
                                                                  margin-top: 15px;
                                                                ">
                          <select v-model="pElement.value.errorImg">
                            <option :value="require('../assets/error.svg')">
                              默认
                            </option>
                            <option v-for="(item, index) in LNoption.image" :key="index" :value="item">
                              {{ item.split("/")[item.split("/").length - 1] }}
                            </option>
                          </select>
                        </div>
                        <img :src="pElement.value.errorImg" style="height: 60px; margin-left: 20px" />
                      </div>
                    </div>
                  </div>
                  <!-- 组件-控制 -->
                  <div v-if="pElement.type == 'data-control'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">数据流</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.stream" @change="streamHandle">
                          <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in streams" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                      </div>
                      <div class="rightNavTextCss">标签</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.label" @change="labelHandle">
                          <option :value="pElement.value.label" v-if="pElement.value.label.labelID == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in label[
                            pElement.value.stream['api-key'] + '_input'
                          ]" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectLabelInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">自定义图片</div>
                      <div style="height: 60px; width: 100%; display: flex">
                        <div class="rightNavSelectCss" style="
                                                                  width: 50%;
                                                                  display: inline-block;
                                                                  margin-top: 15px;
                                                                ">
                          <select v-model="pElement.value.img">
                            <option :value="require('../assets/control.svg')">
                              默认
                            </option>
                            <option v-for="(item, index) in LNoption.image" :key="index" :value="item">
                              {{ item.split("/")[item.split("/").length - 1] }}
                            </option>
                          </select>
                        </div>
                        <img :src="pElement.value.img" style="height: 60px; margin-left: 20px" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">控制方式</div>
                      <div style="height: 60px; width: 100%; display: flex">
                        <div class="rightNavSelectCss" style="
                                                                  width: 50%;
                                                                  display: inline-block;
                                                                  margin-top: 15px;
                                                                ">
                          <select v-model="pElement.value.controlWay" >
                            <option v-for="(item, index) in controlWayList" :key="index" :value="item.key">
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 组件-图片 -->
                  <div v-if="pElement.type == 'data-photo'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">数据流</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.stream" @change="streamHandle">
                          <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in streams" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                      </div>
                      <div class="rightNavTextCss">标签</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.label" @change="labelHandle">
                          <option :value="pElement.value.label" v-if="pElement.value.label.labelID == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in label[
                            pElement.value.stream['api-key'] + '_input'
                          ]" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectLabelInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">刷新频率</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.cycle" />
                      </div>
                      <span class="rightNavSpanCss">秒</span>

                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">异常时显示</div>
                      <div style="height: 60px; width: 100%; display: flex">
                        <div class="rightNavSelectCss" style="
                                                                  width: 50%;
                                                                  display: inline-block;
                                                                  margin-top: 15px;
                                                                ">
                          <select v-model="pElement.value.default">
                            <option :value="require('../assets/default.svg')">
                              默认
                            </option>
                            <option v-for="(item, index) in LNoption.image" :key="index" :value="item">
                              {{ item.split("/")[item.split("/").length - 1] }}
                            </option>
                          </select>
                        </div>
                        <img :src="pElement.value.default" style="height: 60px; margin-left: 20px" />
                      </div>

                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">映射列表</div>
                      <div class="rightNavSeparateCss" />

                      <div class="rightNav-list" v-for="(im, idx) in pElement.value.list" :key="idx">

                        <div class="rightNavTextCss" style="color: #e9e9e9"> </div>

                        <button class="rightNav-delete" @mousedown="RNoptionEdit($event, 'data-photo', idx)">
                          <mu-icon size="16" color="#fff" value="delete" />
                        </button>

                        <div class="rightNavSelectCssInputCss" style="margin-bottom: 15px">
                          <select v-model="im.way">
                            <option value=">">大于</option>
                            <option value="<">小于</option>
                            <option value="=">等于</option>
                            <option value=">=">大于或等于</option>
                            <option value="<=">小于或等于</option>
                          </select>
                          <input type="text" v-model="im.val" />
                        </div>
                        <div style="height: 60px; width: 100%; display: flex">
                          <div class="rightNavSelectCss" style="
                                                                  width: 50%;
                                                                  display: inline-block;
                                                                  margin-top: 15px;
                                                                ">
                            <select v-model="im.img">
                              <option :value="require('../assets/edit/base_photo.svg')">
                                默认
                              </option>
                              <option v-for="(item, index) in LNoption.image" :key="index" :value="item">
                                {{ item.split("/")[item.split("/").length - 1] }}
                              </option>
                            </select>
                          </div>
                          <img :src="im.img" style="height: 60px; width: 60px; margin-left: 20px" />
                        </div>
                        <div class="rightNavSeparateCss" />
                      </div>

                      <div class="rightNavButtonCss" style="margin-top: 10px">
                        <mu-button small color="primary" style="padding: 0 12px"
                          @click="RNoptionEdit($event, 'data-photo', -1)">添加</mu-button>
                      </div>
                    </div>
                  </div>
                  <!-- 组件-音频 -->
                  <div v-if="pElement.type == 'data-audio'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">数据流</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.stream" @change="streamHandle">
                          <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in streams" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                      </div>
                      <div class="rightNavTextCss">标签</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.label" @change="labelHandle">
                          <option :value="pElement.value.label" v-if="pElement.value.label.labelID == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in label[
                            pElement.value.stream['api-key'] + '_input'
                          ]" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectLabelInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange" />
                      </div>
                      <div class="rightNavSeparateCss" />

                      <div class="rightNavTextCss">刷新频率</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.cycle" />
                      </div>
                      <span class="rightNavSpanCss">秒</span>
                      <div class="rightNavSeparateCss" />

                      <div class="rightNavTextCss">音频配置</div>

                      <div class="rightNav-list" v-for="(im, idx) in pElement.value.list" :key="idx">

                        <div class="rightNavTextCss" style="color: #FF000C">运行时如果没有声音，请设置浏览器</div>
                        <div class="rightNavSelectCssInputCss" style="margin-bottom: 15px">
                          <span class="rightNavSelectCssInputTitleCss">播放条件类型</span>
                          <select v-model="im.audioRangeType">
                            <option value="区间">区间</option>
                            <option value="非区间">非区间</option>
                          </select>
                        </div>

                        <div v-if="im.audioRangeType === '非区间'" class="rightNavSelectCssInputCss" style="margin-bottom: 15px">
                          <select v-model="im.way">
                            <option value=">">大于</option>
                            <option value="<">小于</option>
                            <option value="=">等于</option>
                            <option value=">=">大于或等于</option>
                            <option value="<=">小于或等于</option>
                          </select>
                          <input type="text" v-model="im.val" />
                        </div>
                        <div v-if="im.audioRangeType === '区间'">
                          <div class="rightNavSelectCssInputCss" style="margin-bottom: 15px">
                            <select v-model="im.oneWay">
                              <option value=">">大于</option>
                              <option value="<">小于</option>
                              <option value="=">等于</option>
                              <option value=">=">大于或等于</option>
                              <option value="<=">小于或等于</option>
                            </select>
                            <input type="text" v-model="im.oneVal" />
                          </div>
                          <div class="rightNavSelectCssInputCss" style="margin-bottom: 15px">
                            <select v-model="im.twoWay">
                              <option value=">">大于</option>
                              <option value="<">小于</option>
                              <option value="=">等于</option>
                              <option value=">=">大于或等于</option>
                              <option value="<=">小于或等于</option>
                            </select>
                            <input type="text" v-model="im.twoVal" />
                          </div>
                        </div>

                        <div class="rightNavSelectCssInputCss" style="margin-bottom: 15px">
                          <span class="rightNavSelectCssInputTitleCss">选择音频</span>
                          <select v-model="im.audioUrl">
                            <option v-for="(audioItem, audioIndex) in audioMsgList"
                              :key="audioIndex"
                              :value="audioItem.url"
                            >{{ audioItem.name }}</option>
                          </select>
                        </div>

                        <div class="rightNavSelectCssInputCss" style="margin-bottom: 15px">
                          <span class="rightNavSelectCssInputTitleCss">选择图片</span>
                          <select v-model="im.audioImgUrl">
                            <option :value="require('../assets/edit/base_audio.svg')">
                              默认
                            </option>
                            <option v-for="(item, index) in LNoption.image" :key="index" :value="item">
                              {{ item.split("/")[item.split("/").length - 1] }}
                            </option>
                          </select>
                        </div>

                        <div class="rightNavSeparateCss" />
                      </div>
                    </div>
                  </div>
                  <!-- 组件-文本 -->
                  <div v-if="pElement.type == 'data-text'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">数据流</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.stream" @change="streamHandle">
                          <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in streams" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                      </div>
                      <div class="rightNavTextCss">标签</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.label" @change="labelHandle">
                          <option :value="pElement.value.label" v-if="pElement.value.label.labelID == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in label[
                            pElement.value.stream['api-key'] + '_input'
                          ]" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectLabelInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">刷新频率</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.cycle" />
                      </div>
                      <span class="rightNavSpanCss">秒</span>

                      <div class="rightNavTextCss">异常时显示</div>
                      <div class="rightNavTextareaCss">
                        <textarea v-model="pElement.value.default"></textarea>
                      </div>

                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">映射列表</div>
                      <div class="rightNavSeparateCss" />

                      <div class="rightNav-list" v-for="(im, idx) in pElement.value.list" :key="idx">

                        <div class="rightNavTextCss" style="color: #e9e9e9"> </div>

                        <button class="rightNav-delete" @mousedown="RNoptionEdit($event, 'data-text', idx)">
                          <mu-icon size="16" color="#fff" value="delete" />
                        </button>

                        <div class="rightNavSelectCssInputCss" style="margin-bottom: 15px">
                          <select v-model="im.way" @change="labelHandle">
                            <option value=">">大于</option>
                            <option value="<">小于</option>
                            <option value="=">等于</option>
                            <option value=">=">大于或等于</option>
                            <option value="<=">小于或等于</option>
                          </select>
                          <input type="text" v-model="im.val" />
                          <div class="rightNavTextareaCss" style="margin-top: 10px">
                            <textarea v-model="im.cnt"></textarea>
                          </div>
                        </div>
                        <div class="rightNavSeparateCss" />
                      </div>

                      <div class="rightNavButtonCss" style="margin-top: 10px">
                        <mu-button small color="primary" style="padding: 0 12px"
                          @click="RNoptionEdit($event, 'data-text', -1)">添加</mu-button>
                      </div>
                    </div>
                  </div>
                  <!-- 组件-曲线图 -->
                  <div v-if="pElement.type == 'chart-curve'">
                    <div class="rightNavItemCss">

                      <div class="rightNavTextCss">刷新频率</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.cycle" />
                      </div>
                      <span class="rightNavSpanCss">秒</span>

                      <div class="rightNavSeparateCss" />

                      <div class="rightNavTextCss">查询时长</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.timelength" />
                      </div>
                      <span class="rightNavSpanCss">分</span>

                      <div class="rightNavTextCss">查询个数</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.pointlength" />
                      </div>

                      <div class="rightNavSeparateCss" />

                      <div class="rightNavTextCss">线条粗细</div>
                      <div class="rightNavInputCss" style="width: 30%">
                        <input type="text" v-model="pElement.value.linewidth"
                          @change="RNoptionEdit($event, 'chart-curve-linewidth')" />
                      </div>

                      <div class="rightNavTextCss">平滑曲线</div>
                      <div class="rightNavBoxCss" style="
                                                              border: 1px solid #000;
                                                              width: 28px;
                                                              background: #fff;
                                                            " @click="
                                                              RNoptionEdit($event, 'chart-curve-smooth')
                                                            ">
                        <mu-icon size="20" value="done" color="blue" style="margin: 3px" v-show="pElement.value.smooth">
                        </mu-icon>
                      </div>
                      <div class="rightNavTextCss">显示折点</div>
                      <div class="rightNavBoxCss" style="
                                                              border: 1px solid #000;
                                                              width: 28px;
                                                              background: #fff;
                                                            " @click="
                                                              RNoptionEdit($event, 'chart-curve-showSymbol')
                                                            ">
                        <mu-icon size="20" value="done" color="blue" style="margin: 3px"
                          v-show="pElement.value.showSymbol">
                        </mu-icon>
                      </div>

                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">数据列表</div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNav-list" v-for="(im, idx) in pElement.value.list" :key="idx">
                        <div class="rightNavTextCss" style="color: #e9e9e9; width: 206px; margin-right: 3px"> </div>

                        <button class="rightNav-delete" @mousedown="RNoptionEdit($event, 'chart-curve', idx)">
                          <mu-icon size="16" color="#fff" value="delete" />
                        </button>

                        <div class="rightNavTextCss">数据名称</div>
                        <div class="rightNavInputCss" style="width: 95%">
                          <input type="text" v-model="im.name" />
                        </div>

                        <div class="rightNavSeparateCss" />

                        <div class="rightNavTextCss">数据流</div>
                        <div class="rightNavSelectCss">
                          <select v-model="im.stream" @change="streamHandle($event, true, idx)">
                            <option :value="im.stream" v-if="im.stream['api-key'] == ''">
                              请选择
                            </option>
                            <option v-for="(item, index) in streams" :key="index" :value="item">
                              {{ item.name }}
                            </option>
                          </select>
                          <input v-model="selectChartStreamInput[idx]" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange('chart', idx)" />
                        </div>

                        <div class="rightNavTextCss">标签</div>
                        <div class="rightNavSelectCss">
                          <select v-model="im.label" @change="labelHandle($event, im.label, idx)">
                            <option :value="im.label" v-if="im.label.labelID == ''">
                              请选择
                            </option>
                            <option v-for="(item, index) in label[im.stream['api-key'] + '_input']" :key="index" :value="item">
                              {{ item.name }}
                            </option>
                          </select>
                          <input v-model="selectChartLabelInput[idx]" placeholder="请选择" class="rightNavSelectInputCss" @change="selectLabelInputChange('chart', idx)" />
                        </div>

                        <div class="rightNavSeparateCss" />

                        <div class="rightNavTextCss">曲线颜色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            im.linecolor
                          " @click="RNoptionEdit($event, 'chart-curve-linecolor', 'value', im)"></div>
                        </div>

                        <div class="rightNavTextCss">曲线域颜色</div>
                        <div class="rightNavColorboxCss">
                          <div class="rightNavBoxCss" :style="
                            'border: 1px solid #000;background:' +
                            im.linecolorArea
                          " @click="RNoptionEdit($event, 'chart-curve-linecolorArea', 'value', im)"></div>
                        </div>

                        <div class="rightNavSeparateCss" />

                      </div>

                      <div class="rightNavButtonCss" style="margin-top: 10px">
                        <mu-button small color="primary" style="padding: 0 12px"
                          @click="RNoptionEdit($event, 'chart-curve', -1)">添加</mu-button>
                      </div>
                    </div>
                  </div>

                  <!-- 组件-二维表 -->
                  <div v-if="pElement.type == 'table-two'">
                    <div class="rightNavItemCss">
                      <div class="rightNavTextCss">数据流</div>
                      <div class="rightNavSelectCss">
                        <select v-model="pElement.value.stream" @change="streamHandle">
                          <option :value="pElement.value.stream" v-if="pElement.value.stream['api-key'] == ''">
                            请选择
                          </option>
                          <option v-for="(item, index) in streams" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                        <input v-model="selectStreamInput" placeholder="请选择" class="rightNavSelectInputCss" @change="selectSteamInputChange" />
                      </div>
                      <div class="rightNavSeparateCss" />
                      <div class="rightNavTextCss">仪表数据名称</div>
                      <div class="rightNavInputCss" style="width: 50%">
                        <input type="text" v-model="pElement.value.tableList[0].captionName" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
        </div>
      </div>
      <div v-else>
        <div class="multipleRightNavCss">
          <div class="rightNavItemCss"  v-if="selectedElement.length >= 2">
            <div class="rightNavButtonCss">
              <mu-button small color="primary" style="padding: 0 12px"
                @click="alignment('上对齐')">上对齐</mu-button>
            </div>
            <div class="rightNavSeparateCss" />
            <div class="rightNavButtonCss">
              <mu-button small color="primary" style="padding: 0 12px"
                @click="alignment('下对齐')">下对齐</mu-button>
            </div>
            <div class="rightNavSeparateCss" />
            <div class="rightNavButtonCss">
              <mu-button small color="primary" style="padding: 0 12px"
                @click="alignment('左对齐')">左对齐</mu-button>
            </div>
            <div class="rightNavSeparateCss" />
            <div class="rightNavButtonCss">
              <mu-button small color="primary" style="padding: 0 12px"
                @click="alignment('右对齐')">右对齐</mu-button>
            </div>
            <div class="rightNavSeparateCss" />
          </div>
          <div class="rightNavItemCss" v-if="selectedElement.length >= 1">
            <div class="rightNavButtonCss">
              <mu-button small color="error" style="padding: 0 12px"
              @click="multipleDelect">批量删除</mu-button>
              <span class="delectSpanCss">DEL</span>
            </div>
            <div class="rightNavSeparateCss" />
            <div class="rightNavTextCss">移动步长</div>
            <div class="rightNavTextareaCss">
              <input v-model="moveStep" type="number" :min="1" />
            </div>
            <div class="rightNavSeparateCss" />
            <div class="rightNavTextCss">文本设置</div>
            <div class="rightNavManyboxCss">
              <select style="width: 25%" v-for="(item, index) in textFields" :key="index"
                v-model="selectedElementStyle.fontStyle[index]" @change="selectedFontStyle($event, 'style-fontSet')">
                <option :value="im.val" v-for="(im, idx) in item" :key="idx">
                  {{ im.key }}
                </option>
              </select>
            </div>
            <div class="rightNavTextCss">文本样式</div>
            <div class="rightNavManyboxCss" style="font-family: 'wryh'">
              <button style="color: #e53232" @click="selectedFontStyle($event, 'style-color')">
                A
              </button>
              <button style="font-weight: bold" @click="selectedFontStyle($event, 'style-fontWeight')">
                B
              </button>
              <button style="font-style: italic" @click="selectedFontStyle($event, 'style-fontStyle')">
                I
              </button>
              <button style="text-decoration: underline" @click="selectedFontStyle($event, 'style-textDecoration')">
                U
              </button>
            </div>
            <div class="rightNavSeparateCss" />
            <div class="rightNavButtonCss">
              <mu-button small color="primary" style="padding: 0 12px"
                @click="selectedFontStyle($event, 'box-lock')">锁定</mu-button>
            </div>
            <div class="rightNavSeparateCss" />
            <div class="rightNavTextCss">背景色</div>
            <div class="rightNavColorboxCss">
              <div class="rightNavBoxCss" :style="
                'border: 1px solid #000;background:' +
                muSlideColor.value.setColorRgba
              " @click="selectedFontStyle($event, 'box-background')">
              </div>
            </div>
            <div class="rightNavDetailsCss">
              <div></div>
              <span>显示设置</span>
              <div></div>
            </div>
            <div class="rightNavTextCss">大小</div>
            <div class="rightNavItemListCss">
              <span>宽</span>
              <div class="rightNavInputCss" style="width: 60px">
                <input type="number" v-model="selectedElementStyle.box.width" @input="selectedFontStyle($event, 'box-width')" />
              </div>
              <span>高</span>
              <div class="rightNavInputCss" style="width: 60px">
                <input type="number" v-model="selectedElementStyle.box.height" @input="selectedFontStyle($event, 'box-height')" />
              </div>
            </div>
            <div class="rightNavSeparateCss" />
            <div class="rightNavTextCss">旋转</div>
            <div style="height: 30px; width: 100%; display: flex">
              <mu-slider
                style="flex: 1; margin: 0 16px 0 0; height: 30px"
                v-model="selectedElementStyle.box.rotate"
                :max="359" :step="1"
                @change="setMultipleBoxSet"
              />
              <div class="rightNavInputCss" style="width: 60px">
                <input type="number" :min="0" :max="359" v-model="selectedElementStyle.box.rotate" @input="selectedFontStyle($event, 'box-rotate')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 调色盘 -->
    <mu-slide-right-transition>
      <div class="pcColorCss" v-if="muSlideColor.enable" :style="'top:' + muSlideColor.top + 'px;left:' + muSlideColor.left + 'px;'">
        <v-color style="box-shadow: none; background: none" :isGradient="true" v-model="muSlideColor.value"
          :disableAlpha="muSlideColor.alpha" @input="colorHandle('input')" />
        <div style="text-align: center; padding-bottom: 5px">
          <mu-button fab small color="pink" style="margin-right: 40px; height: 34px; width: 34px"
            @click="colorHandle('clear')">
            <mu-icon value="clear" />
          </mu-button>
          <mu-button fab small color="primary" style="height: 34px; width: 34px" @click="colorHandle('check')">
            <mu-icon value="check" />
          </mu-button>
        </div>
      </div>
    </mu-slide-right-transition>
    <!-- 素材拖块 -->
    <div class="pcDragCss" :style="getdragBox()" v-if="dragBox.enable">
      <div v-if="drag.from == 'base'" :class="'leftNav-' + drag.from">
        <div>
          <mu-icon size="40" color="grey700" :value="drag.config.index" />
        </div>
        <span>{{ drag.config.item }}</span>
      </div>
      <div v-if="drag.from == 'module'" :class="'leftNav-' + drag.from">
        <img :src="drag.config.img" draggable="false" />
        <div>{{ drag.config.title }}</div>
      </div>
      <div v-if="drag.from == 'dataValue'" :class="'leftNav-' + drag.from">
        <img :src="drag.config.img" draggable="false" />
        <div>{{ drag.config.title }}</div>
      </div>
      <div v-if="drag.from == 'material'" :class="'leftNav-' + drag.from">
        <img :src="drag.config" draggable="false" />
      </div>
      <div v-if="drag.from == 'active'" :class="'leftNav-' + drag.from">
        <img :src="drag.config.img" draggable="false" />
        <div>{{ drag.config.title }}</div>
      </div>
      <div v-if="drag.from == 'image'" :class="'leftNav-' + drag.from">
        <div>
          <img :src="drag.config" draggable="false" />
        </div>
        <span>{{
          drag.config.split("/")[drag.config.split("/").length - 1]
        }}</span>
      </div>
    </div>
    <!-- 右键菜单 -->
    <mu-expand-transition>
      <div class="pcMenuCss" v-if="menu.enable" :style="'top:' + menu.pos.y + 'px;left:' + menu.pos.x + 'px;'"
        @contextmenu.prevent="&quot;return&quot;;">
        <div class="pcMenuItemCss" v-for="(item, index) in menu.list" :key="index" v-show="item.show" :style="
          (item.enable ? '' : 'color:#bfc5d1;cursor:no-drop;') +
          (item.border ? 'border-bottom: 1px solid #dfe2e9;' : '')
        " @click.stop="menuItemClick(item)">
          {{ item.title }}
          <span>{{ item.prompt }}</span>
        </div>
      </div>
    </mu-expand-transition>
    <!-- 下发控制 -->
    <mu-expand-transition>
      <div class="pcControlCss" v-if="control.enable">
        <div class="pcControlHeadCss">
          <span>下发数据</span>
          {{
            control.item.value.label.name == ""
            ? "未绑定数据"
            : control.item.value.label.name
          }}
        </div>
        <div class="pcControlBodyCss">
          <div class="pcControlViewCss">
            <div class="pcControlViewItemCss" v-for="(item, index) in control.log" :key="index">
              发送数据：{{ item.value }}
              <span style="float: right">{{ getTime(item.point) }}</span>
              <span style="float: right; margin-right: 10px">{{
                item.msg
              }}</span>
            </div>
          </div>
          <textarea rows="3" v-model="control.value"></textarea>
          <div class="pcControlBtnCss">
            <mu-button small color="grey50" @click="control_Handle('cancel')" style="color: #000">关闭</mu-button>
            <mu-button small color="primary" @click="control_Handle('send')">发送</mu-button>
          </div>
        </div>
      </div>
    </mu-expand-transition>
  </div>
  </div>
</template>

<script>
import keymaster from 'keymaster' // 组合键绑定，参考地址：https://blog.csdn.net/Lxinccode/article/details/126729029
import { Sketch } from "vue-color"
import Table from "@/components/table/Table.vue"

const lodash = require('lodash')

import {
  tolink_Stream,
  tolink_Label,
  tolink_Realtime,
  tolink_Mqttwrite,
  tolink_GetLine,
} from "../network/tolink.js";

import {
  dynalt_SetToken,
  dynalt_Retrieve,
  dynalt_Config,
  dynalt_Update,
  dynalt_Material_Private,
  dynalt_Image_Upload,
  dynalt_Image_List,
  dynalt_Image_Delete,
  dynalt_Audio_List,
  dynalt_SetUrl,
} from "../network/dynalt.js";

import { tolink_SetToken, tolink_SetUrl } from "../network/tolink.js";

import {
  funcUL,
  LNoption,
  menu,
  textFields,
  control,
  regPos,
  regNeg,
  getUrlKey,
  controlWayList,
} from "../assets/option.js";
import { randomString } from '@/utils/utils.js'
import { setPictureList, setPCanvas } from '../utils/yzt.js'

import * as echarts from "echarts";

export default {
  components: {
    "v-color": Sketch,
    Table,
  },
  data() {
    return {
      bannerWidth: 300, // 左侧导航条加工具栏宽度
      bannerHeight: 60, // 顶部导航条高度

      ok: false,
      dynaltToken: "",
      tolinkToken: "",
      userid: "",
      id: "",
      url: "",
      dynalt: {},
      realtime: { update: 0 },

      allStream: [], // 全部数据流列表
      streams: [], // 数据流列表
      label: [],
      apiKeyCur: "",

      selectStreamInput: '', // 选择的数据流输入框数据
      selectLabelInput: '', // 选择的标签输入框数据

      selectChartStreamInput: [], // 曲线选择的数据流输入框数据
      selectChartLabelInput: [], // 曲线选择的数据流输入框数据

      viewLock: "",

      leftNavHidden: false, // 左侧功能栏是否显示
      rightNavHidden: true, // 右侧功能栏是否显示
      funcULCur: -1,
      rightNavCur: true,
      centerShow: false,

      RNoption: {
        editEnable: true,
        auxLine: true,
      },

      canvasCur: 0,
      elementCur: -1,

      echarts: {
        curve: [],
      },

      echartTemplate: {
        curve: {
          client: null,
          option: {
            animationDuration: 500,
            grid: {
              show: false,
              left: "10%",
              top: "60",
              right: "10%",
              bottom: "60",
            },
            xAxis: {
              type: "time",
              splitLine: {
                show: false,
              },
            },
            yAxis: {
              type: "value",
              // max: function (value) {
              //   if (value.min - (value.max - value.min) / 3 < 0) {
              //     return (value.max * 1.25).toFixed(3);
              //   } else {
              //     return (value.max + (value.max - value.min) / 3).toFixed(3);
              //   }
              // },
              // min: function (value) {
              //   return value.min - (value.max - value.min) / 3 > 0
              //     ? (value.min - (value.max - value.min) / 3).toFixed(3)
              //     : 0;
              // },
            },
            tooltip: {
              axisPointer: {
                type: "cross",
              },
              order: "valueDesc",
              trigger: "axis",
              formatter(params) {
                var date = new Date(params[0].data[0]);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                var d = date.getDate();
                var h = date.getHours();
                var i = date.getMinutes();
                var s = date.getSeconds();

                m = m < 10 ? "0" + m : m;
                d = d < 10 ? "0" + d : d;
                h = h < 10 ? "0" + h : h;
                i = i < 10 ? "0" + i : i;
                s = s < 10 ? "0" + s : s;
                var result =
                  y +
                  "-" +
                  m +
                  "-" +
                  d +
                  " " +
                  h +
                  ":" +
                  i +
                  ":" +
                  s +
                  "</br>- - - - - - -</br>";
                var dotHtml_h =
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:';
                var dtHtml_t = '"></span>';
                params.forEach(function (item) {
                  if (item.data[2] < 1) {
                    item.color = "#ce3535";
                    item.data[1] = "异常";
                  }
                  result +=
                    dotHtml_h +
                    item.color +
                    dtHtml_t +
                    '<span style="display:inline-block;margin-right:6px">' +
                    item.data[3] +
                    '</span><span style="display:inline-block;float:right">' +
                    item.data[1] +
                    "</span>" +
                    "</br>";
                });
                return result;
              },
            },
            dataset: [],
            series: [],
          },
        },
      },

      muSlideColor: {
        enable: false,
        value: "#fff",
        alpha: false,
        type: "hex8",
        cache: "",
        pointer: {},
        pkey: "",
        top: 0,
        left: 0,
      },
      mouse: {
        pos: { x: 0, y: 0 },
        click: { enbale: false, x: 0, y: 0 },
        move: { x: 0, y: 0 },
      },
      board: {
        enable: false,
        top: 60,
        left: 300,
        x: 0,
        y: 0,
      },
      drag: {
        id: "",
        from: "",
        config: {},
      },
      dragBox: {
        enable: false,
        top: 0,
        left: 0,
        x: 0,
        y: 0,
        r: 0,
      },
      eleBox: {
        enable: false,
        action: "",
        pointer: null,
        cursor: "",
        x: 0,
        y: 0,
        w: 0,
        h: 0,
      },

      funcUL,
      LNoption,
      menu,
      textFields,
      regPos,
      regNeg,
      control,

      time: {
        unix: 0,
        local: "now time",
      },
      windowWidth: document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,

      videoList: [
        { key: "1", name: "海康云视频接口(直播地址)" },
        { key: "2", name: "海康云视频接口(监控地址)" },
        { key: "3", name: "国际28181协议" },
      ],

      isShowSelectMask: false, // 是否显示编辑区框选内容的遮罩层
      selectStartX: 0, // 框选内容的起始X坐标
      selectStartY: 0, // 框选内容的起始Y坐标
      selectEndX: 0, // 框选内容的结束X坐标
      selectEndY: 0, // 框选内容的结束Y坐标

      selectedElement: [], // 多选被选中的组件
      waitCopyElementList: [], // 待复制的组件列表

      selectedElementStyle: { // 多选被选中组件的样式
        fontStyle: {},
        box: {}
      },

      controlWayList, // 组件-控制 的方式列表

      streamData: '', // 数据组件中选择的数据流
      streamList: [], // 数据组件中的数据流列表
      selectStreamDataInput: '', // 数据组件中数据流筛选输入框

      allLabelList: [], // 全部的标签列表
      labelList: [], // 数据组件中的标签列表
      selectLabelDataInput: '', // 数据组件中的标签筛选输入框

      fontTemplList: [], // 字体模板列表

      moveStep: 1, // 键盘按键移动步长

      audioMsgList: [], // 音频列表

      yZhouFzxList: [], // 纵轴辅助线列表
      xZhouFzxList: [] // 横轴辅助线列表
    }
  },
  computed: {
    leftNavStyle() {
      return this.leftNavHidden
        ? "transform: translateX(-240px);transition: transform 0.5s;"
        : ""
    },
    rightNavStyle() {
      return this.rightNavHidden
        ? "transform: translateX(240px);transition: transform 0.5s;"
        : ""
    },
    pCanvas() { // 组态图数据集合
      return setPCanvas(this)
    },
    pElement() { // 被选中的组件
      if (this.elementCur < 0) {
        return {};
      }
      return this.pCanvas.element[this.elementCur];
    },
    selectMaskLeft() { // 框选内容的left
      return `${Math.min(this.selectStartX, this.selectEndX)}`
    },
    selectMaskTop() { // 框选内容的top
      return `${Math.min(this.selectStartY, this.selectEndY)}`
    },
    selectMaskWidth() { // 框选内容宽度
      return `${Math.abs(this.selectEndX - this.selectStartX)}`
    },
    selectMaskHeight() { // 框选内容高度
      return `${Math.abs(this.selectEndY - this.selectStartY)}`
    },
    yZhouFzxStyle() { // 纵轴辅助线样式
      return (item) => {
        const style = {
          left: item.left
        }
        if (item.bkColor) style['background-color'] = item.bkColor
        return style
      }
    },
    xZhouFzxStyle() { // 横轴辅助线样式
      return (item) => {
        const style = {
          top: item.top
        }
        if (item.bkColor) style['background-color'] = item.bkColor
        return style
      }
    }
  },
  watch: {
    funcULCur() {
      switch (this.funcULCur) {
        case 2:
          this.dynaltMaterialPrivate();
          break;
        case 4:
          this.dynaltImageList(this.id);
          break;
      }
    },
    elementCur() {
      this.rightNavCur = true;
      if ("value" in this.pElement) {
        if (typeof this.pElement.value == "object") {
          if ("stream" in this.pElement.value) {
            if (this.pElement.value.stream["api-key"] != this.apiKeyCur) {
              this.apiKeyCur = this.pElement.value.stream["api-key"];
              this.tolinkLabel(this.pElement.value.stream["api-key"]);
            }
          }
        }
        if (this.pElement.type == "chart-curve") {
          for (let item of this.pElement.value.list) {
            this.apiKeyCur = item.stream["api-key"];
            this.tolinkLabel(item.stream["api-key"]);
          }
        }
      }
    },
  },
  mounted() {
    this.init()
    this.getFzxLocalStorage() // 获取保存到本地的辅助线
    this.setKeymaster() // 设置组合键
    this.dynaltAudioPrivate() // 获取音频素材列表
  },
  methods: {
    init() {
      this.id = getUrlKey("id");
      this.userid = getUrlKey("userid");
      this.tolinkToken = getUrlKey("tolinkToken");
      this.dynaltToken = getUrlKey("dynaltToken");
      this.url = document.URL;

      tolink_SetToken(this.tolinkToken);
      // https处理 //
      if (this.userid == "tolink.ditoo.com.cn") {
        this.userid = "business.ditoo.com.cn";
      }
      if (this.userid == "iot.lbyun.cn") {
        this.userid = "manager.lbyun.cn";
      }
      if (this.userid == "dicloud.tolink.ditoo.com.cn") {
        this.userid = "dicloud.ditoo.com.cn";
      }
      if (this.userid == "tolink.scnee-cloud.com") {
        this.userid = "scnee-cloud.com";
      }
      if (this.userid == "tolink.hsqc.ditoo.com.cn") {
        this.userid = "hsqc.ditoo.com.cn";
      }
      if (this.userid == "tolink.kghj.tolinkyun.com") {
        this.userid = "kghj.tolinkyun.com";
      }
      if (this.userid == "tolink.hsqc.website") {
        this.userid = "hsqc.website";
      }
      if (this.userid == "tolink.cd-kght.cn") {
        this.userid = "cd-kght.cn";
      }

      if (this.userid.indexOf(':') !== -1) { // 采集云使用的是ip，不是域名
          this.head = 'http'
          tolink_SetUrl('http://' + this.userid)
          if (this.userid === `10.30.201.240:83` || // 中自采集云
          this.userid === '10.30.201.240:80' || // 中自业务云
          this.userid === '10.30.201.240:8000') { // 中自环保ip（10.30.201.240为运行环境ip）
            dynalt_SetUrl('http://' + '10.30.201.240:8000') // 中自环保本地ip
            tolink_SetUrl('http://' + '10.30.201.240:83')
          } else if (this.userid === `192.168.0.68:80` || this.userid === `192.168.0.68:83`) { // 蒙毅龙头石
            dynalt_SetUrl('http://192.168.0.68:8001')
          } else if (this.userid === `cdz.zxfhgroup.com:8003` || this.userid === `cdz.zxfhgroup.com:83`) { // 龙头石内网
            this.head = 'https'
            dynalt_SetUrl('https://cdz.zxfhgroup.com:8001')
            tolink_SetUrl('https://' + this.userid)
          } else if (this.userid === `172.16.11.11:80` || this.userid === `172.16.11.11:83`) { // 天元重工
            dynalt_SetUrl('http://172.16.11.11:8001')
          } else if (this.userid === `10.0.3.165:80` || this.userid === `10.0.3.165:83`) { // 浙江永贵
            dynalt_SetUrl('http://10.0.3.165:8001')
          } else if (this.userid === `223.87.76.70:80` || this.userid === `223.87.76.70:83`) { // 众鑫盛外网
            // tolink_SetUrl('http://' + '223.87.76.70:83')
            // dynalt_SetUrl('http://223.87.76.70:8001')
          } else if (this.userid === `192.168.1.33:80` || this.userid === `192.168.1.33:83`) { // 万友滤机
            dynalt_SetUrl('http://192.168.1.33:8001')
          } else if (this.userid === `iot.kaisaier.com.cn:8003` || this.userid === `iot.kaisaier.com.cn:83`) { // 凯赛尔
            dynalt_SetUrl('https://iot.kaisaier.com.cn:8001')
            tolink_SetUrl('https://' + 'iot.kaisaier.com.cn:83')
          } else if (this.userid === `192.168.0.146:80` || this.userid === `192.168.0.146:83`) { // 测试服务
            dynalt_SetUrl('http://192.168.0.146:8001')
          } else if (this.userid === `localhost`) { // 简晟打包
            dynalt_SetUrl('http://localhost:83')
          } else if(this.userid === `110.185.210.70:8880` || this.userid === `110.185.210.70:8881`) { // 盛帮外网
            dynalt_SetUrl('http://' + '110.185.210.70:8882')
            tolink_SetUrl('http://' + '110.185.210.70:8880')
            if (location.hostname === '192.168.0.251') { // 盛帮内网
              dynalt_SetUrl('http://' + '192.168.0.251:8882')
              tolink_SetUrl('http://' + '192.168.0.251:8880')
            }
          } else if (this.userid === `111.9.53.181:8004` || this.userid === `111.9.53.181:8003`) { // 锂硫电池朔源平台
            dynalt_SetUrl('http://111.9.53.181:8001')
          } else {
            dynalt_SetUrl('http://dynalt2.ditoo.com.cn')
          }
        } else {
          if (this.userid === `tolink.schcec.com`) { // 蓉城汉昌
            tolink_SetUrl('http://' + this.userid) // 采集云的接口域名
            dynalt_SetUrl('http://dynalt.schcec.com') // 云组态的接口域名
          } else {
            tolink_SetUrl('https://' + this.userid)
          }
        }

      // -- -- -- //

      dynalt_SetToken(this.dynaltToken)

      window.onresize = () => {
        return (() => {
          this.windowHeight = document.documentElement.clientHeight;
          this.windowWidth = document.documentElement.clientWidth;
        })();
      };

      this.dynaltRetrieve(this.id) // 获取画布全部信息

      let interval = setInterval(() => {
        if (this.ok) {
          clearInterval(interval);

          this.tolinkStream();

          this.dynaltMaterialPrivate();
          this.dynaltImageList(this.id);

          setInterval(() => {
            this.time.unix = new Date().getTime() / 1000;
            this.time.local = this.getDatetime();
          }, 1000);

          setInterval(this.activeHandle, 10);
          setInterval(this.dataHandle, 1000);

          setTimeout(() => {
            this.rightNavHidden = false;
          }, 100);
          setTimeout(() => {
            this.funcULCur = 0;
          }, 200);
          setTimeout(() => {
            this.centerShow = true;
          }, 650);
        }
      }, 100);
    },
    setKeymaster() { // 设置按键
      keymaster('ctrl+c', () => {
        this.copyElement() // 复制组件
        return false  // 回调函数返回 false 以阻止浏览器默认事件行为
      })
      keymaster('ctrl+v', () => {
        this.pasteElement() // 粘贴组件
        return false  // 回调函数返回 false 以阻止浏览器默认事件行为
      })
      keymaster('del', () => {
        this.removeFzx() // 删除辅助线
        this.multipleDelect() // 批量删除
        return false  // 回调函数返回 false 以阻止浏览器默认事件行为
      })
      keymaster('left', () => {
        this.moveDynalt('left') // 移动被选中的组件
        return false  // 回调函数返回 false 以阻止浏览器默认事件行为
      })
      keymaster('right', () => {
        this.moveDynalt('right') // 移动被选中的组件
        return false  // 回调函数返回 false 以阻止浏览器默认事件行为
      })
      keymaster('down', () => {
        this.moveDynalt('down') // 移动被选中的组件
        return false  // 回调函数返回 false 以阻止浏览器默认事件行为
      })
      keymaster('up', () => {
        this.moveDynalt('up') // 移动被选中的组件
        return false  // 回调函数返回 false 以阻止浏览器默认事件行为
      })
      keymaster('ctrl+a', () => {
        this.selectAll() // 选择全部组件
        return false  // 回调函数返回 false 以阻止浏览器默认事件行为
      })
    },
    /* - - - - - - - */
    urlHandle(url) {
      if (typeof(url) === 'string') {
        switch (this.head) {
          case 'http':
            return url.replace(/https/g, 'http')
        }
      }
      return url
    },
    funcHandle(func) {
      switch (func) {
        case "save":
          let config = lodash.cloneDeep(this.dynalt.config);
          for (let idx in config.canvas) {
            for (let idx2 in config.canvas[idx].element) {
              config.canvas[idx].element[idx2].isMultiple = false
              if ("value" in config.canvas[idx].element[idx2]) {
                if (typeof config.canvas[idx].element[idx2].value == "object") {
                  if ("update" in config.canvas[idx].element[idx2].value) {
                    config.canvas[idx].element[idx2].value.update = false;
                  }
                }
              }
            }
          }
          this.dynaltConfig(this.dynalt.id, config);
          break;
        case "release":
          this.dynaltUpdate({
            id: this.dynalt.id,
            name: this.dynalt.name,
            password: this.dynalt.password,
            public: this.dynalt.public,
            release: true,
          });
          break;
        case "view":
          let routeUrl = this.$router.resolve({
            path: "/board",
            query: { id: this.dynalt.id, userid: this.userid },
          });
          window.open(routeUrl.href, "_blank");
          break;
        case 'fzsx': // 辅助 竖线
          this.setFzx('竖线') // 设置辅助 竖线
          break
        case 'qcfzsx': // 删除辅助 竖线
          this.yZhouFzxList = []
          this.saveFzxLocalStorage() // 辅助线保存到本地
          break
        case 'fzxx': // 辅助 横线
          this.setFzx('横线') // 设置辅助 横线
          break
        case 'qcfzxx': // 删除辅助 横线
          this.xZhouFzxList = []
          this.saveFzxLocalStorage() // 辅助线保存到本地
          break
      }
    },
    setFzx(type) { // 设置辅助竖线
      let countNum = 0 // 用于计算的辅助线偏移量的数值
      const boardWdith = this.dynalt.config.board.width
      const boardHeight = this.dynalt.config.board.height
      const maxNum = 30 // 最大编号
      const maxNumOut = maxNum + 1 // 最大值加一
      let minNum = maxNumOut // 当前所能增加的最小编号
      if (type === '竖线') {
        countNum = boardWdith
        for (let i = 0; i < maxNumOut; i++) {
          let hasNum = false // yZhouFzxList 中的key 是否存在对应的 i
          this.yZhouFzxList.forEach(item => {
            if (item.key === i) {
              hasNum = true
            }
          })
          if (!hasNum) { // 不存在
            if (minNum > i) {
              minNum = i
            }
          }
        }
      } else if (type === '横线') {
        countNum = boardHeight
        for (let i = 0; i < maxNumOut; i++) {
          let hasNum = false // xZhouFzxList 中的key 是否存在对应的 i
          this.xZhouFzxList.forEach(item => {
            if (item.key === i) {
              hasNum = true
            }
          })
          if (!hasNum) { // 不存在
            if (minNum > i) {
              minNum = i
            }
          }
        }
      }
      if (minNum === maxNumOut) {
        return
      }
      const fzxData = {
        key: minNum,
        index: randomString(10), // 随机码
        bkColor: null // 辅助线选中时的背景色
      }
      let posPyl = 0 // 绝对定位偏移量
      switch(fzxData.key) {
        case 0: posPyl = countNum * (1 / 2); break
        case 1: posPyl = countNum * (1 / 4); break
        case 2: posPyl = countNum * (3 / 4); break
        case 3: posPyl = countNum * (3 / 8); break
        case 4: posPyl = countNum * (5 / 8); break
        case 5: posPyl = countNum * (1 / 8); break
        case 6: posPyl = countNum * (7 / 8); break
        case 7: posPyl = countNum * (7 / 16); break
        case 8: posPyl = countNum * (9 / 16); break
        case 9: posPyl = countNum * (5 / 16); break
        case 10: posPyl = countNum * (11 / 16); break
        case 11: posPyl = countNum * (3 / 16); break
        case 12: posPyl = countNum * (13 / 16); break
        case 13: posPyl = countNum * (1 / 16); break
        case 14: posPyl = countNum * (15 / 16); break
        case 15: posPyl = countNum * (15 / 32); break
        case 16: posPyl = countNum * (17 / 32); break
        case 17: posPyl = countNum * (13 / 32); break
        case 18: posPyl = countNum * (19 / 32); break
        case 19: posPyl = countNum * (11 / 32); break
        case 20: posPyl = countNum * (21 / 32); break
        case 21: posPyl = countNum * (9 / 32); break
        case 22: posPyl = countNum * (23 / 32); break
        case 23: posPyl = countNum * (7 / 32); break
        case 24: posPyl = countNum * (25 / 32); break
        case 25: posPyl = countNum * (5 / 32); break
        case 26: posPyl = countNum * (27 / 32); break
        case 27: posPyl = countNum * (3 / 32); break
        case 28: posPyl = countNum * (29 / 32); break
        case 29: posPyl = countNum * (1 / 32); break
        case 30: posPyl = countNum * (31 / 32); break
      }
      if (type === '竖线') {
        fzxData.left = posPyl + 'px' // 绝对定位左偏移量
        this.yZhouFzxList.push(fzxData)
      } else if (type === '横线') {
        fzxData.top = posPyl + 'px' // 绝对定位上偏移量
        this.xZhouFzxList.push(fzxData)
      }
      this.saveFzxLocalStorage() // 辅助线保存到本地
    },
    clickYZhouFzx(item) { // 点击纵轴辅助线
      this.yZhouFzxList.forEach(fzxItem => {
        if (fzxItem.index === item.index) {
          if (fzxItem.bkColor) { // 辅助线背景色
            fzxItem.bkColor = null
          } else {
            fzxItem.bkColor = '#25ACFD'
          }
        }
      })
    },
    clickXZhouFzx(item) { // 点击横轴辅助线
      this.xZhouFzxList.forEach(fzxItem => {
        if (fzxItem.index === item.index) {
          if (fzxItem.bkColor) { // 辅助线背景色
            fzxItem.bkColor = null
          } else {
            fzxItem.bkColor = '#25ACFD'
          }
        }
      })
    },
    removeFzx() { // 删除辅助线
      const yZhouFzxList = []
      this.yZhouFzxList.forEach(fzxItem => {
        if (!fzxItem.bkColor) {
          yZhouFzxList.push(fzxItem)
        }
      })
      this.yZhouFzxList = yZhouFzxList

      const xZhouFzxList = []
      this.xZhouFzxList.forEach(fzxItem => {
        if (!fzxItem.bkColor) {
          xZhouFzxList.push(fzxItem)
        }
      })
      this.xZhouFzxList = xZhouFzxList
      this.saveFzxLocalStorage() // 辅助线保存到本地
    },
    saveFzxLocalStorage() { // 辅助线保存到本地
      const yZhouFzxListStr = JSON.stringify(this.yZhouFzxList)
      localStorage.setItem('yZhouFzxListStr', yZhouFzxListStr)

      const xZhouFzxListStr = JSON.stringify(this.xZhouFzxList)
      localStorage.setItem('xZhouFzxListStr', xZhouFzxListStr)
    },
    getFzxLocalStorage() { // 获取保存到本地的辅助线
      const yZhouFzxListStr = localStorage.getItem('yZhouFzxListStr')
      if (yZhouFzxListStr) this.yZhouFzxList = JSON.parse(yZhouFzxListStr)

      const xZhouFzxListStr = localStorage.getItem('xZhouFzxListStr')
      if (xZhouFzxListStr) this.xZhouFzxList = JSON.parse(xZhouFzxListStr)
    },
    /* - - - - - - - */

    replay_Event() {
      window.location.href = this.url;
    },
    switchvideo_Event(idx) {
      if (idx >= 0) {
        this.canvasCur = idx;
        this.elementCur = -1;
      }
    },
    link_Event(url) {
      window.open(url);
    },
    switch_Event(item, val) {
      if (
        item.value.stream["api-key"] == "" ||
        item.value.label.labelID == ""
      ) {
        this.$alert("开关未绑定数据", "提示", {
          okLabel: "知道了",
        });
        return;
      }
      this.$confirm(
        (val == 0 ? "关闭 " : "开启 ") + item.value.label.name + "？",
        "提示",
        {}
      ).then(({ result }) => {
        if (result) {
          this.tolinkMqttwrite(
            item.value.stream["api-key"],
            item.value.label.labelID,
            val
          );
        }
      });
    },
    control_Event(item) {
      this.control = { enable: true, item, value: "", log: [] };
    },
    control_Handle(btn) {
      switch (btn) {
        case "send":
          if (
            this.control.item.value.stream["api-key"] == "" ||
            this.control.item.value.label.labelID == ""
          ) {
            return;
          }
          if (
            !this.regPos.test(this.control.value) &&
            !this.regNeg.test(this.control.value)
          ) {
            this.control.log.unshift({
              value: this.control.value,
              time: Math.round(new Date().getTime() / 1000),
              msg: "请输入数字",
            });
            return;
          }
          this.tolinkMqttwrite(
            this.control.item.value.stream["api-key"],
            this.control.item.value.label.labelID,
            parseFloat(this.control.value),
            true
          );
          break;
        case "cancel":
          this.control = { enable: false, item: null, value: "", log: [] };
          break;
      }
    },
    dataPhoto_Handle(ele) {
      let vaule = this.dataShow(
        this.realtime[ele.value.label.labelID],
        "value",
        this.realtime.update
      );

      switch (vaule) {
        case "暂无":
        case "异常":
          return require("../assets/edit/base_photo.svg");
      }

      for (let idx in ele.value.list) {
        let result = false;
        switch (ele.value.list[idx].way) {
          case ">":
            if (vaule > ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "<":
            if (vaule < ele.value.list[idx].val) {
              result = true;
            }
            break;
          case ">=":
            if (vaule >= ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "<=":
            if (vaule <= ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "=":
            if (vaule == ele.value.list[idx].val) {
              result = true;
            }
            break;
        }
        if (result) {
          return ele.value.list[idx].img;
        }
      }

      return "";
    },
    dataAudio_Handle(ele) { // 音频图片显示
      let audioImgUrl = ele.value.list[0].audioImgUrl
      if (audioImgUrl === '') {
        audioImgUrl = require("../assets/edit/base_audio.svg")
      }
      return audioImgUrl
    },
    dataText_Handle(ele) {
      let vaule = this.dataShow(
        this.realtime[ele.value.label.labelID],
        "value",
        this.realtime.update
      );

      switch (vaule) {
        case "暂无":
        case "异常":
          return ele.value.default;
      }

      for (let idx in ele.value.list) {
        let result = false;
        switch (ele.value.list[idx].way) {
          case ">":
            if (vaule > ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "<":
            if (vaule < ele.value.list[idx].val) {
              result = true;
            }
            break;
          case ">=":
            if (vaule >= ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "<=":
            if (vaule <= ele.value.list[idx].val) {
              result = true;
            }
          case "=":
            if (vaule == ele.value.list[idx].val) {
              result = true;
            }
            break;
        }
        if (result) {
          return ele.value.list[idx].cnt;
        }
      }

      return "";
    },

    editNav_Event(func, idx) {
      switch (func) {
        case "show":
          this.elementCur = -1;
          this.canvasCur = idx;
          break;
        case "delete":
          this.$confirm(
            "确认删除画布 “" + this.dynalt.config.canvas[idx].name + "” ？",
            {}
          ).then(({ result }) => {
            if (result) {
              this.dynalt.config.canvas.splice(idx, 1);
              this.canvasCur = 0;
            }
          });
          break;
      }
    },

    curve_Init(ele) {
      if (ele.value.echartid in this.echarts.curve) {
        if (this.echarts.curve[ele.value.echartid].client !== null) {
          this.echarts.curve[ele.value.echartid].client.dispose();
          this.echarts.curve[ele.value.echartid].client = null;
        }
      }
      this.echarts.curve[ele.value.echartid] = lodash.cloneDeep(
        this.echartTemplate.curve
      );

      let echart_p = this.echarts.curve[ele.value.echartid];

      echart_p.option.dataset = [];
      echart_p.option.series = [];

      echart_p.option.dataset.push({ id: "dataset_raw", source: [] });
      for (let item of ele.value.list) {
        echart_p.option.dataset.push({
          id: item.name,
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "data", "=": item.name }],
            },
          },
        });

        echart_p.option.series.push({
          type: "line",
          datasetId: item.name,
          name: item.name,
          showSymbol: ele.value.showSymbol,
          smooth: ele.value.smooth,
          encode: {
            x: "time",
            y: "value",
          },
          lineStyle: {
            color: item.linecolor,
            type: "solid",
            cap: "round",
            width: ele.value.linewidth,
          },
          areaStyle: {
            color: item.linecolorArea,
          },
        });
      }
    },

    curve_Update(ele) {
      let echart_p = this.echarts.curve[ele.value.echartid];

      if (echart_p.client != null) {
        echart_p.client.dispose();
        echart_p.client = null;
      }

      echart_p.client = echarts.init(
        document.getElementById(ele.value.echartid)
      );

      if (echart_p.option.dataset[0].source.length == 0) {
        echart_p.option.dataset[0].source = [
          ["time", "value", "status", "data"],
        ];
      }

      echart_p.client.setOption(echart_p.option);
    },

    /* - - - - - - - */

    streamHandle(e, isList, idx) {
      if (isList) {
        this.pElement.value.update = false;
        this.pElement.value.list[idx].label = { labelID: '' } // 将label置为空
        this.selectChartLabelInput[idx] = '' // 将label输入框置为空
        const apiKey = this.pElement.value.list[idx].stream["api-key"]
        if (apiKey != this.apiKeyCur) {
          this.apiKeyCur = this.pElement.value.list[idx].stream["api-key"];
          this.tolinkLabel(this.pElement.value.list[idx].stream["api-key"]);
        }
        this.selectChartStreamInput[idx] = this.pElement.value.list[idx].stream.name
      } else {
        this.pElement.value.update = false;
        this.pElement.value.label = { labelID: "" };
        if (this.pElement.value.stream["api-key"] != this.apiKeyCur) {
          this.apiKeyCur = this.pElement.value.stream["api-key"];
          this.tolinkLabel(this.pElement.value.stream["api-key"]);
        }
        this.setSelectInpt() // 设置选择的输入框数据
      }
    },
    labelHandle(e, label, idx) {
      this.pElement.value.update = false
      if (label) { // 曲线数据标签
        this.selectChartLabelInput[idx] = label.name
      } else {
        this.setSelectInpt() // 设置选择的输入框数据
      }
    },
    selectSteamInputChange(type, idx) { // 选择框获取焦点事件
      let selectStreamInput = this.selectStreamInput
      if (type === 'chart') {
        selectStreamInput = this.selectChartStreamInput[idx]
      }
      const showStream = [] // 显示出的数据池列表
      this.allStream.forEach(item => {
        if (item.name.indexOf(selectStreamInput) !== -1) {
          showStream.push(item)
        }
      })
      this.streams = showStream
    },
    setSelectInpt() { // 设置选择的输入框数据
      this.selectLabelInput = this.pElement.value.label.name
    },
    selectLabelInputChange(type, idx) { // 选择框获取焦点事件
      if (type === 'chart') {
        const allLabel = this.label[this.pElement.value.list[idx].stream["api-key"]] // 全部标签
        const showLabel = [] // 显示出的标签
        allLabel.forEach(item => {
          if (item.name.indexOf(this.selectChartLabelInput[idx]) !== -1) {
            showLabel.push(item)
          }
        })
        this.label[this.pElement.value.list[idx].stream["api-key"] + '_input'] = showLabel
      } else {
        const allLabel = this.label[this.pElement.value.stream['api-key']] // 全部标签
        const showLabel = [] // 显示出的标签
        allLabel.forEach(item => {
          if (item.name.indexOf(this.selectLabelInput) !== -1) {
            showLabel.push(item)
          }
        })
        this.label[this.pElement.value.stream['api-key'] + '_input'] = showLabel
      }
    },
    resetInpt() { // 重置选择的输入框数据
      this.selectStreamInput = ''
      this.selectLabelInput = ''
      this.selectChartStreamInput = []
      this.selectChartLabelInput = []
    },
    setSelectInpt() { // 设置选择的输入框数据
      this.selectStreamInput = this.pElement.value.stream.name
      this.selectLabelInput = this.pElement.value.label.name
    },
    dataHandle() {
      if (Object.keys(this.dynalt).length == 0) return;
      let request = {};
      let cycle = 0;
      let timeNow = parseInt(new Date().getTime() / 1000);

      for (let canvas of this.dynalt.config.canvas) {
        for (let element of canvas.element) {
          switch (element.type) {
            case "active":
              switch (element.style.type) {
                case "active-pipe": // 管道
                case "active-pool": // 水池
                  break;
              }
            case "data-value":
            case "data-switch":
            case "data-photo":
            case "data-audio":
            case "data-text":
              cycle =
                Math.trunc(element.value.cycle) < 3 ? 3 : element.value.cycle;

              if (
                (timeNow % cycle == 0 || !element.value.update) &&
                element.value.stream["api-key"] != "" &&
                element.value.label &&
                element.value.label["labelID"] != ""
              ) {
                element.value.update = true;
                if (!(element.value.stream["api-key"] in request)) {
                  request[element.value.stream["api-key"]] = [];
                }
                let exist = false;
                for (let labelID of request[element.value.stream["api-key"]]) {
                  if (labelID == element.value.label.labelID) {
                    exist = true;
                    break;
                  }
                }
                if (!exist) {
                  request[element.value.stream["api-key"]].push(
                    element.value.label.labelID
                  );
                }
              }
              break;
            case "chart-curve":
              cycle =
                Math.trunc(element.value.cycle) < 10 ? 10 : element.value.cycle;

              if (timeNow % cycle == 0 || !element.value.update) {
                element.value.update = true;
                this.tolinkGetData(element);
              }

              break;
          }
        }
      }

      if (Object.keys(request).length > 0) {
        for (let index in request) {
          this.tolinkRealtime(index, request[index]);
        }
      }
    },

    /* - - - - - - - */

    activeHandle() {
      if (Object.keys(this.dynalt).length == 0) return;
      for (let canvas of this.dynalt.config.canvas) {
        for (let element of canvas.element) {
          if (element.type == "active") {
            switch (element.style.type) {
              case "active-pipe":
                this.active_pipeHandle(element);
                break;
              case "active-pool":
                this.active_poolHandle(element)
                break
            }
          }
        }
      }
    },
    active_pipeHandle(ele) { // 设置管道流动
      let canvas = document.getElementById(ele.style.id);
      if (canvas == undefined) return;
      var context = canvas.getContext("2d");

      if (ele.value.enable == true) {
        let vaule = this.dataShow(
          this.realtime[ele.value.label.labelID],
          "value",
          this.realtime.update
        );
        if (vaule == "暂无" || vaule == "异常") {
          ele.style.move += 0.6;
        } else {
          const setZxValue = parseFloat(ele.value.positive.val) // 设置的正向流动的值
          let result = false;
          switch (ele.value.positive.way) {
            case "=":
              if (vaule === setZxValue) {
                result = true;
              }
            break;
            case ">":
              if (vaule > setZxValue) {
                result = true;
              }
              break;
            case "<":
              if (vaule < setZxValue) {
                result = true;
              }
              break;
            case ">=":
              if (vaule >= setZxValue) {
                result = true;
              }
              break;
            case "<=":
              if (vaule <= setZxValue) {
                result = true;
              }
              break;
          }
          if (result == true) {
            ele.style.move -= 0.6;
          }

          const setFxValue = parseFloat(ele.value.reverse.val) // 设置的反向流动的值
          result = false;
          switch (ele.value.reverse.way) {
            case "=":
              if (vaule === setFxValue) {
                result = true;
              }
              break
            case ">":
              if (vaule > setFxValue) {
                result = true;
              }
              break;
            case "<":
              if (vaule < setFxValue) {
                result = true;
              }
              break;
            case ">=":
              if (vaule >= setFxValue) {
                result = true;
              }
              break;
            case "<=":
              if (vaule <= setFxValue) {
                result = true;
              }
              break;
          }

          if (result == true) {
            ele.style.move += 0.6;
          }
        }
      } else {
        ele.style.move += 0.6;
      }

      if (ele.style.move >= ele.box.height * 3) {
        ele.style.move = 0;
      }

      if (ele.style.move <= -ele.box.height * 3) {
        ele.style.move = 0;
      }

      context.clearRect(0, 0, ele.box.width, ele.box.height);
      context.lineWidth = 0.01;
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
      context.shadowBlur = 0;
      context.shadowColor = "RGB(42, 154, 199)";

      let gradient = context.createLinearGradient(
        0,
        0,
        0,
        ele.box.height * 0.5
      );
      gradient.addColorStop(0, "#8c96aa");
      gradient.addColorStop(1, "#ffffff");

      let gradient2 = context.createLinearGradient(
        0,
        ele.box.height * 0.5,
        0,
        ele.box.height
      );
      gradient2.addColorStop(0, "#ffffff");
      gradient2.addColorStop(1, "#8c96aa");

      context.fillStyle = gradient;
      context.fillRect(0, 0, ele.box.width, ele.box.height * 0.5);

      context.fillStyle = gradient2;
      context.fillRect(
        0,
        ele.box.height * 0.5,
        ele.box.width,
        ele.box.height * 0.5
      );

      context.shadowBlur = 3;
      context.fillStyle = ele.style.color;

      for (let idx = -1; idx < ele.box.width / (ele.box.height * 3); idx++) {
        context.fillRect(
          idx * ele.box.height * 3 + ele.style.move,
          ele.box.height * 0.17,
          ele.box.height * 2,
          ele.box.height * 0.66
        );
      }

      context.fill();
    },
    active_poolHandle(ele) {
      let canvas = document.getElementById(ele.style.id);
      if (canvas == undefined) return;
      let context = canvas.getContext("2d");

      let range = 0;
      let show = "";
      let vaule = this.dataShow(
        this.realtime[ele.value.label.labelID],
        ele.value.content,
        this.realtime.update
      );

      switch (vaule) {
        case "暂无":
        case "异常":
          range = ele.value.max / 100;
          show = vaule;
          break;
        default:
          range = (vaule - ele.value.min) / (ele.value.max - ele.value.min);
          show = Math.trunc(range * 100) + "%";
      }

      if (range < 0) {
        range = 0;
        show = "<0%";
      }
      if (range > 1) {
        range = 1;
        show = ">100%";
      }
      if (ele.value.max <= ele.value.min) {
        range = 0;
        show = "峰值？";
      }

      ele.style.move = ele.style.move > 100 ? 0 : ele.style.move + 0.05;

      context.clearRect(0, 0, ele.box.width, ele.box.height);
      context.beginPath();

      let points = [];
      for (var x = 0; x < 0 + ele.box.width; x += 20 / ele.box.width) {
        var y = -Math.sin((0 + x) * 0.01 + ele.style.move);
        var dY = ele.box.height * (1 - range);
        points.push([x, dY + y * 6]);
        context.lineTo(x, dY + y * 6);
      }

      context.lineTo(ele.box.width, ele.box.height);
      context.lineTo(0, ele.box.height);
      context.lineTo(points[0][0], points[0][1]);
      context.fillStyle = ele.style.waveColor;
      context.fill();
      context.restore();

      context.textAlign = "center";
      context.fillStyle = ele.style.color;
      context.font = ele.style.fontSize + "px wryh";
      context.fillText(
        ele.value.show ? show : "",
        ele.box.width / 2,
        ele.box.height / 2
      );
    },

    /* - - - - - - - */

    mouseDown(event) {
      if (event.button === 0) {
        this.mouse.click = { enable: true, x: event.x, y: event.y };
      }
    },
    mouseUp(event) { // 鼠标松开
      if (this.mouse.click.enable) {
        this.mouse.click = { enable: false, x: 0, y: 0 };
        this.mouse.move = { x: 0, y: 0 };
      }

      if (this.board.enable) {
        this.board.enable = false;
      }
      if (this.dragBox.enable) {
        this.dragBox.enable = false;
        if (this.mouse.pos.x < this.bannerWidth || this.mouse.pos.y < this.bannerHeight) return;
        let ele = null;
        let top =
          this.dragBox.top +
          this.$refs.pcEditRef.scrollTop -
          (this.board.top + 50);
        let left =
          this.dragBox.left +
          this.$refs.pcEditRef.scrollLeft -
          (this.board.left + 60);
        switch (this.drag.from) { // 在编辑区创建组件
          case "base":
            ele = this.createBase(this.drag.id, top, left);
            break;
          case "module":
            ele = this.createModule(this.drag.id, top, left);
            break;
          case "dataValue":
            ele = this.createModule(this.drag.id, top, left);
            if (this.dragBox.labelMsg) { // 是否属于便捷数据
              ele.value.stream = this.streamData
              ele.value.label = this.dragBox.labelMsg
            } else {
              this.resetInpt() // 重置选择的输入框数据
            }
            break;
          case "material":
            ele = this.createMaterial(this.drag.id, top, left);
            break;
          case "active":
            ele = this.createActive(this.drag.id, top, left);
            break;
          case "image":
            ele = this.createImage(this.drag.id, top, left);
            break;
          default:
            return;
        }
        if (ele == null) return;

        this.pCanvas.element.push(ele);
        this.elementCur = this.pCanvas.element.length - 1;
      }
      if (this.eleBox.enable) {
        this.eleBox.enable = false;
        this.eleBox.cursor = "";
        switch (this.pElement.type) {
          case "chart-curve":
            this.pElement.value.update = false;
            break;
        }
      }
    },
    mouseMove(event) {
      this.mouse.pos.x = event.x;
      this.mouse.pos.y = event.y;
      this.mouse.client = {
        clientX: event.clientX,
        clientY: event.clientY
      }

      if (this.mouse.click.enable) {
        this.mouse.move = {
          x: this.mouse.pos.x - this.mouse.click.x,
          y: this.mouse.pos.y - this.mouse.click.y,
        };
      }

      if (this.board.enable) {
        this.board.top = this.board.y + this.mouse.move.y;
        this.board.left = this.board.x + this.mouse.move.x;
      }

      if (this.dragBox.enable) {
        this.dragBox.top = this.dragBox.y + this.mouse.move.y;
        this.dragBox.left = this.dragBox.x + this.mouse.move.x;
      }

      if (this.eleBox.enable) {
        let box = this.eleBox.pointer;
        this.eleBox.y = parseInt(this.eleBox.y);
        this.eleBox.x = parseInt(this.eleBox.x);
        switch (this.eleBox.action) {
          case "element":
            box.top = this.eleBox.y + this.mouse.move.y;
            box.left = this.eleBox.x + this.mouse.move.x;
            break;
          case "rotate":
            const radians = Math.atan2(
              this.mouse.pos.x -
              (60 + this.board.left + this.eleBox.x + this.eleBox.w / 2),
              this.mouse.pos.y -
              (60 + this.board.top + this.eleBox.y + this.eleBox.h / 2)
            );
            box.rotate = Math.trunc(radians * (180 / Math.PI) * -1 + 180);
            break;
          default:
            if (this.eleBox.action.indexOf("n") != -1) {
              box.top =
                this.mouse.pos.y -
                (this.board.top - this.$refs.pcEditRef.scrollTop + (60 - 10));
              box.height = this.eleBox.h - this.mouse.move.y;
            }
            if (this.eleBox.action.indexOf("e") != -1) {
              box.width =
                this.mouse.pos.x -
                (this.eleBox.x +
                  this.board.left -
                  this.$refs.pcEditRef.scrollLeft +
                  60 +
                  5);
            }
            if (this.eleBox.action.indexOf("s") != -1) {
              box.height =
                this.mouse.pos.y -
                (this.eleBox.y +
                  this.board.top -
                  this.$refs.pcEditRef.scrollTop +
                  60 +
                  10);
            }
            if (this.eleBox.action.indexOf("w") != -1) {
              box.left =
                this.mouse.pos.x -
                (this.board.left - this.$refs.pcEditRef.scrollLeft + 60 - 10);
              box.width = this.eleBox.w - this.mouse.move.x;
            }
            box.top = Math.trunc(
              box.top - (this.eleBox.y + this.eleBox.h - 10) > 0
                ? this.eleBox.y + this.eleBox.h - 10
                : box.top
            );
            box.left = Math.trunc(
              box.left - (this.eleBox.x + this.eleBox.w - 10) > 0
                ? this.eleBox.x + this.eleBox.w - 10
                : box.left
            );
            box.width = Math.trunc(box.width < 10 ? 10 : box.width);
            box.height = Math.trunc(box.height < 10 ? 10 : box.height);
        }
      }
    },
    handleMouseDown(e) { // 监听编辑区鼠标按下事件
      if (e.button == 0 && !this.RNoption.editEnable) {
        this.board = {
          enable: true,
          top: this.board.top,
          left: this.board.left,
          x: this.board.left,
          y: this.board.top,
        };
      }
      if (this.elementCur < 0 || this.pElement.box.lock) { // 没有选中组件或选中的组件被锁定
        if (this.selectedElement.length === 0) { // 组件没有被多选
          this.selectEndX = e.offsetX
          this.selectEndY = e.offsetY
          this.isShowSelectMask = true
        }
        this.selectStartX = e.offsetX
        this.selectStartY = e.offsetY
        
        document.body.addEventListener("mousemove", this.handleMouseMove); // 监听鼠标框选移动事件
        document.body.addEventListener("mouseup", this.handleMouseUp); // 监听鼠标框选抬起事件
      }
    },
    handleMouseMove(e) { // 监听鼠标框选移动事件
      const offsetX = this.selectEndX + e.movementX
      const offsetY = this.selectEndY + e.movementY
      if (this.elementCur < 0 || this.pElement.box.lock) { // 没有选中组件或选中的组件被锁住
        if (this.selectedElement.length === 0) { // 组件没有被多选
          this.selectEndX = offsetX
          this.selectEndY = offsetY
        } else {
          this.selectedElement.forEach(seleItem => {
            const elementBox = seleItem.box
            elementBox.left = parseFloat(elementBox.left) + e.movementX
            elementBox.top = parseFloat(elementBox.top) +e.movementY
          })
        }
      }
    },
    handleMouseUp() { // 监听鼠标框选抬起事件
      this.isShowSelectMask = false
      document.body.removeEventListener("mousemove", this.handleMouseMove) // 卸载监听鼠标框选移动事件
      document.body.removeEventListener("mouseup", this.handleMouseUp) // 卸载监听鼠标框选抬起事件
      if (this.elementCur < 0 || this.pElement.box.lock) { // 没有选中组件或选中的组件被锁住
        if (this.selectedElement.length === 0) { // 组件没有被多选
          this.multipleChoice() // 多选组件
        }
      }
    },
    multipleChoice() { // 多选组件
      const selectLeft = parseFloat(this.selectMaskLeft) // 编辑框中选择区最左侧的点坐标
      const selectTop = parseFloat(this.selectMaskTop) // 编辑框中选择区最顶部的点坐标
      const selectRight = selectLeft + parseFloat(this.selectMaskWidth) // 编辑框中选择区最右侧的点坐标
      const selectBottom = selectTop + parseFloat(this.selectMaskHeight) // 编辑框中选择区最底部的点坐标
      const selectedElement = []
      this.pCanvas.element.forEach(item => {
        const elementBox = item.box
        const lock = elementBox.lock // 是否被锁定
        const left = parseFloat(elementBox.left)
        const top = parseFloat(elementBox.top)
        const right = left + parseFloat(elementBox.width)
        const bottom = top + parseFloat(elementBox.height)
        if (!lock && selectLeft < left &&
        selectTop < top &&
          selectRight > right &&
          selectBottom > bottom) {
          item.isMultiple = true // 多选被选中
          selectedElement.push(item)
        }
      })
      this.selectedElement = selectedElement
    },
    alignment(type) { // 对齐
      if (type === '上对齐') {
        let top = null
        this.selectedElement.forEach(item => {
          const elementBox = item.box
          if (!top || top > elementBox.top) {
            top = elementBox.top
          }
        })
        this.selectedElement.forEach(item => {
          const elementBox = item.box
          elementBox.top = top
        })
      } else if (type === '下对齐') {
        let bottom = null
        this.selectedElement.forEach(item => {
          const elementBox = item.box
          const elementBottom = elementBox.top + parseFloat(elementBox.height)
          if (!bottom || bottom < elementBottom) {
            bottom = elementBottom
          }
        })
        this.selectedElement.forEach(item => {
          const elementBox = item.box
          elementBox.top = bottom - parseFloat(elementBox.height)
        })
      } else if (type === '左对齐') {
        let left = null
        this.selectedElement.forEach(item => {
          const elementBox = item.box
          if (!left || left > elementBox.left) {
            left = elementBox.left
          }
        })
        this.selectedElement.forEach(item => {
          const elementBox = item.box
          elementBox.left = left
        })
      } else if (type === '右对齐') {
        let right = null
        this.selectedElement.forEach(item => {
          const elementBox = item.box
          const elementRight = elementBox.left + parseFloat(elementBox.width)
          if (!right || right < elementRight) {
            right = elementRight
          }
        })
        this.selectedElement.forEach(item => {
          const elementBox = item.box
          elementBox.left = right - parseFloat(elementBox.width)
        })
      }
    },
    resetSelectedElement() { // 重置selectedElement
      this.selectedElement.forEach(item => {
        item.isMultiple = false // 多选被选中
      })
      this.selectedElement = [] // 清空被多选的组件

      this.selectedElementStyle = { // 多选被选中组件的样式
        fontStyle: {},
        box: {}
      }
    },
    copyElement() { // 复制组件
      const waitCopyElementList = []
      this.selectedElement.forEach(item => {
        const elementMsg = lodash.cloneDeep(item)
        elementMsg.isMultiple = false // 多选被选中
        item.id = randomString(10) // id不能重复
        if (item.echartid) { // id不能重复
          item.echartid = randomString(10)
        }
        if (item.style && item.style.id) { // id不能重复
          item.style.id = randomString(10)
        }
        waitCopyElementList.push(elementMsg)
      })
      this.waitCopyElementList = waitCopyElementList
    },
    pasteElement() { // 粘贴组件
      this.selectedElement.forEach(item => { // 将当前选中的组件设置为未被选中
        item.isMultiple = false // 多选被选中
      })
      this.waitCopyElementList.forEach(item => {
        this.pCanvas.element.forEach(eleItem => {
          if (item.id === eleItem.id) { // 防止id重复
            item.id = randomString(10)
          }
          if (item.echartid && item.echartid === eleItem.echartid) { // 防止id重复
            item.echartid = randomString(10)
          }
          if (item.style && item.style.id && item.style.id === eleItem.style.id) { // 防止id重复
            item.style.id = randomString(10)
          }
        })
      })
      const addElement = [] // 粘贴后增加的组件
      this.waitCopyElementList.forEach(item => {
        item.isMultiple = true // 多选被选中
        const elementMsg = lodash.cloneDeep(item)
        elementMsg.box.top = parseInt(elementMsg.box.top) + 30
        elementMsg.box.left = parseInt(elementMsg.box.left) + 30
        this.pCanvas.element.push(elementMsg)
        addElement.push(elementMsg)
      })

      this.selectedElement = addElement // 重置被选中的组件
      this.copyElement() // 重置等待被复制的组件
    },
    multipleDelect() { // 批量删除
      const elementList = []
      this.pCanvas.element.forEach(item => {
        let isSameElement = false // 组件是否相同
        this.selectedElement.forEach(selItem => {
          if (item.id === selItem.id) {
            isSameElement = true
          }
        })
        if (!isSameElement) {
          elementList.push(item)
        }
      })
      this.pCanvas.element = elementList
      this.selectedElement = []
    },
    moveDynalt(direction) { // 移动被选中的组件
      const moveStep = parseInt(this.moveStep)
      if (this.elementCur >= 0) {
        const box = this.pElement.box
        box.left = parseInt(box.left)
        box.top = parseInt(box.top)
        if (!box.lock) {
          switch(direction) {
            case 'left': box.left -= moveStep; break
            case 'right': box.left += moveStep; break
            case 'up': box.top -= moveStep; break
            case 'down': box.top += moveStep; break
          }
        }
      }
      this.selectedElement.forEach(item => {
        item.box.left = parseInt(item.box.left)
        item.box.top = parseInt(item.box.top)
        switch(direction) {
          case 'left': item.box.left = parseFloat(item.box.left) - moveStep; break
          case 'right': item.box.left = parseFloat(item.box.left) + moveStep; break
          case 'up': item.box.top = parseFloat(item.box.top) - moveStep; break
          case 'down': item.box.top = parseFloat(item.box.top) + moveStep; break
        }
      })
    },
    selectAll() { // 选择全部组件
      const selectedElement = []
      this.pCanvas.element.forEach(item => {
        const lock = item.box.lock // 是否被锁定
        if (!lock) {
          item.isMultiple = true // 多选被选中
          selectedElement.push(item)
        }
      })
      this.selectedElement = selectedElement
    },
    selectedFontStyle(event, key) { // 批量设置选择的组件的样式
      const p = event.target.getBoundingClientRect()
      switch (key) {
        case "nav-background":
        case "nav-color":
        case "nav-checkColor":
          break;
        case "nav-enable":
          break;
        case "canvas":
        case "canvas-background":
          break;
        case "style-color":
        case "style-waveColor":
        case "box-background":
          this.selectColorEnable( // 打开批量选择的组件的调色盘
            key,
            p.top,
            p.left
          );
          break;
        case "style-fontWeight":
        case "style-fontStyle":
        case "style-textDecoration":
        case "box-lock":
          this.setMultipleFontStyle(key) // 批量设置字体样式
          break;
        case "data-photo":
          break;
        case "data-text":
          break;
        case "chart-curve":
          break;
        case "chart-curve-linecolor":
          break;
        case "chart-curve-linecolorArea":
          break;
        case "chart-curve-smooth":
          break;
        case "chart-curve-showSymbol":
          break;
        case "chart-curve-linewidth":
          break;
        case 'style-fontSet':
          this.setMultipleFontSet() // 批量设置字体大小、行高等样式
          break
        case 'box-width':
        case 'box-height':
        case 'box-rotate':
          this.setMultipleBoxSet() // 批量设置元素容器大小旋转角度
          break
          
      }
    },
    selectColorEnable(key, top, left) { // 打开批量选择的组件的调色盘
      if (top > this.windowHeight / 2) {
        this.muSlideColor.top = top - 348 - 10;
      } else {
        this.muSlideColor.top = top + 40;
      }
      this.muSlideColor.isMultipleSelected = true // 是否属于被批量选中时打开的
      this.muSlideColor.left = left - 110 + 14;
      this.muSlideColor.pkey = key;
      this.muSlideColor.enable = true;
    },
    setMultipleColor() { // 批量设置颜色
      const setColorRgbaObj = this.muSlideColor.value.rgba
      const setColorRgba = `rgba(${setColorRgbaObj.r}, ${setColorRgbaObj.g}, ${setColorRgbaObj.b}, ${setColorRgbaObj.a})`
      this.muSlideColor.value.setColorRgba = setColorRgba
      this.selectedElement.forEach(item => {
        if (item.box && item.box.background) {
          if (this.muSlideColor.pkey === 'box-background') {
            item.box.background = setColorRgba
          }
        }
        if (item.style && item.style.color) {
          if (this.muSlideColor.pkey === 'style-color') {
            item.style.color = setColorRgba
          }
        }
      })
    },
    setMultipleFontStyle(key) { // 批量设置字体样式
      this.selectedElement.forEach(item => {
        if (item.style && item.style.fontWeight) { // 是否加粗
          if (key === 'style-fontWeight') {
            item.style.fontWeight = item.style.fontWeight === 'normal' ? 'bold' : 'normal'
          }
        }
        if (item.style && item.style.fontStyle) { // 是否斜体
          if (key === 'style-fontStyle') {
            item.style.fontStyle = item.style.fontStyle === 'normal' ? 'italic' : 'normal'
          }
        }
        if (item.style && item.style.textDecoration) { // 是否加下划线
          if (key === 'style-textDecoration') {
            item.style.textDecoration = item.style.textDecoration === 'none' ? 'underline' : 'none'
          }
        }
        if (item.box) { // 是否锁定
          if (key === 'box-lock') {
            if (item.box.lock === false) {
              item.box.lock = true
              this.resetSelectedElement() // 重置selectedElement
            }
          }
        }
      })
    },
    setMultipleFontSet() { // 批量设置字体大小、行高等样式
      const multipleFontStyle = this.selectedElementStyle.fontStyle
      this.selectedElement.forEach(item => {
        if (item.style && item.style.fontSize) { // 设置字体大小
          if (multipleFontStyle.fontSize) {
            item.style.fontSize = multipleFontStyle.fontSize
          }
        }
        if (item.style && item.style.fontFamily) { // 设置字体类型
          if (multipleFontStyle.fontFamily) {
            item.style.fontFamily = multipleFontStyle.fontFamily
          }
        }
        if (item.style && item.style.lineHeight) { // 设置字体行高
          if (multipleFontStyle.lineHeight) {
            item.style.lineHeight = multipleFontStyle.lineHeight
          }
        }
        if (item.style && item.style.textAlign) { // 设置字体对其方式
          if (multipleFontStyle.textAlign) {
            item.style.textAlign = multipleFontStyle.textAlign
          }
        }
      })
    },
    setMultipleBoxSet() { // 批量设置元素容器大小旋转角度
      const multipleBox = this.selectedElementStyle.box
      this.selectedElement.forEach(item => {
        if (item.box && item.box.width) { // 设置元素容器宽度
          if (multipleBox.width) {
            multipleBox.width = parseFloat(multipleBox.width)
            item.box.width = multipleBox.width
          }
        }
        if (item.box && item.box.height) { // 设置元素容器高度
          if (multipleBox.height) {
            multipleBox.height = parseFloat(multipleBox.height)
            item.box.height = multipleBox.height
          }
        }
        if (item.box && (item.box.rotate || item.box.rotate === 0)) { // 设置元素容器旋转角度
          if (multipleBox.rotate || multipleBox.rotate === 0) {
            multipleBox.rotate = parseFloat(multipleBox.rotate)
            item.box.rotate = multipleBox.rotate
          }
        }
      })
    },
    /* - - - - - - - */

    createBase(id, top, left) {
      let ele = {
        id: randomString(10),
        type: id,
        box: {
          width: "160",
          height: "40",
          top,
          left,
          rotate: 0,
          lock: false,
          background: "#FFFFFF00",
        },
        style: {
          fontSize: "12",
          fontFamily: "wryh",
          lineHeight: "1",
          textAlign: "left",
          color: "#000000",
          fontWeight: "normal",
          fontStyle: "normal",
          textDecoration: "none",
        },
      };
      switch (id) {
        case "text_fields":
          ele.value = '字体'
          ele.isSyncTitle = false // 是否同步标题
          ele.isFontTempl = false // 是否设为字体模板
          ele.fontTemplName = '' // 字体模板名称
          ele.fontTemplId = null // 使用的字体模板id
          break;
        case "date_range":
          ele.value = this.time;
          break;
        case "link":
          ele.value = { name: "超链接", to: "http://cn.bing.com" };
          break;
        case "replay":
          ele.value = "刷新页面";
          break;
        case "switch_video":
          ele.value = { name: "栏目", to: -1 };
          break;
        case "web":
          ele.box.width = "600";
          ele.box.height = "300";
          delete ele.style;
          ele.value = "http://cn.bing.com/";
          break;
        case "fullscreen":
          ele.box.width = "30";
          ele.box.height = "30";
          delete ele.style;
          ele.value = false;
          break;
        case "camera":
          ele.box.width = "30";
          ele.box.height = "30";
          delete ele.style;
          ele.value = true;
          break;
        case "landscape_screen":
          ele.box.width = "30";
          ele.box.height = "30";
          delete ele.style;
          ele.value = true;
          break;
      }
      return ele;
    },
    createMaterial(url, top, left) {
      let ele = {
        id: randomString(10),
        type: "material",
        box: {
          width: "103",
          height: "116",
          top,
          left,
          rotate: 0,
          lock: false,
          background: "#FFFFFF00",
        },
        value: url,
      };
      return ele;
    },
    createImage(url, top, left) {
      let ele = {
        id: randomString(10),
        type: "image",
        box: {
          width: "105",
          height: "118",
          top,
          left,
          rotate: 0,
          lock: false,
          background: "#FFFFFF00",
        },
        value: url,
      };
      return ele;
    },
    createActive(id, top, left) {
      let ele = {
        id: randomString(10),
        type: "active",
        box: {
          width: "300",
          height: "260",
          top,
          left,
          rotate: 0,
          lock: false,
          background: "#FFFFFF00",
        },
        style: { type: id, id: randomString(10), move: 0 },
      };
      switch (id) {
        case "active-pipe":
          ele.box.width = "400";
          ele.box.height = "20";
          ele.style.color = "#2a9ac7ff";
          ele.value = {
            cycle: 3,
            stream: { "api-key": "" },
            label: { labelID: "" },
            enable: false,
            positive: { way: ">=", val: 0 },
            reverse: { way: "<", val: 0 },
          };
          break;
        case "active-pool":
          ele.style.waveColor = "#1c86d1ff";
          ele.style.color = "#065580ff";
          ele.style.fontSize = 20;
          ele.value = {
            update: false,
            show: false,
            content: "value",
            cycle: 3,
            max: 90,
            min: 0,
            stream: { "api-key": "" },
            label: { labelID: "" },
          }
          break
        default:
          return null;
      }
      return ele;
    },
    createModule(id, top, left) {
      let ele = {
        id: randomString(10),
        type: id,
        box: {
          width: "105",
          height: "105",
          top,
          left,
          rotate: 0,
          lock: false,
          background: "#FFFFFF00",
        },
      };
      switch (id) {
        case "data-video":
          ele.box.width = "500";
          ele.box.height = "300";
          ele.value = {
            videoType: "1",
            autoplay: 0,
            url: "",
            AppKey: "",
            Secret: "",
            AccessToken: "",

            stream: { "api-key": "" },
          };
          break;
        case "data-value":
          ele.box.width = "160";
          ele.box.height = "40";
          ele.style = {
            fontSize: "12",
            fontFamily: "wryh",
            lineHeight: "1",
            textAlign: "left",
            color: "#000000",
            fontWeight: "normal",
            fontStyle: "normal",
            textDecoration: "none",
          };
          ele.value = {
            update: false,
            content: "value",
            cycle: 3,
            bit: 2,
            stream: { "api-key": "" },
            label: { labelID: "" },
          };
          break;
        case "data-switch":
          ele.value = {
            update: false,
            cycle: 3,
            bit: 3,
            openImg: require("../assets/open.svg"),
            closeImg: require("../assets/close.svg"),
            errorImg: require("../assets/error.svg"),
            stream: { "api-key": "" },
            label: { labelID: "" },
          };
          break;
        case "data-control":
          ele.box.width = "50";
          ele.box.height = "50";
          ele.value = {
            img: require("../assets/control.svg"),
            stream: { "api-key": "" },
            label: { labelID: "", name: "" },
            controlWay: 0
          };
          break;
        case "data-photo":
          ele.value = {
            update: false,
            cycle: 3,
            bit: 3,
            default: require("../assets/default.svg"),
            list: [],
            stream: { "api-key": "" },
            label: { labelID: "" },
          };
          break;
        case "data-audio":
          ele.box.width = "30";
          ele.box.height = "30";
          ele.value = {
            update: false,
            cycle: 3,
            bit: 3,
            default: require("../assets/default.svg"),
            list: [{
              way: '>', // 非区间的范围条件
              val: '', // 非区间的范围条件值
              audioRangeType: '非区间', // 播放范围类型
              oneWay: '>', // 区间的范围条件1
              oneVal: '0', // 区间的范围条件1的值
              twoWay: '<', // 区间的范围条件2
              twoVal: '100', // 区间的范围条件2的值
              audioUrl: '', // 音频路径
              audioImgUrl: '' // 音频图片路径
            }],
            stream: { "api-key": "" },
            label: { labelID: "" },
          };
          break;
        case "data-text":
          ele.box.width = "160";
          ele.box.height = "40";
          ele.style = {
            fontSize: "12",
            fontFamily: "wryh",
            lineHeight: "1",
            textAlign: "left",
            color: "#000000",
            fontWeight: "normal",
            fontStyle: "normal",
            textDecoration: "none",
          };
          ele.value = {
            update: false,
            cycle: 3,
            bit: 3,
            default: "异常时显示文本",
            list: [],
            stream: { "api-key": "" },
            label: { labelID: "" },
          };
          break;
        case "chart-curve":
          ele.box.width = "600";
          ele.box.height = "310";
          ele.value = {
            update: false,
            cycle: 10,
            timelength: 24 * 60,
            pointlength: 20,
            echartid: Math.round(new Date() / 1000),
            showSymbol: true,
            smooth: true,
            linewidth: 2.5,
            list: [],
            stream: {
              'api-key': ''
            }
          };
          break;
        case "table-two":
          ele.box.width = "300";
          ele.box.height = "300";
          ele.style = {
            fontSize: "12",
            fontFamily: "wryh",
            lineHeight: "1",
            textAlign: "left",
            color: "#000000",
            fontWeight: "normal",
            fontStyle: "normal",
            textDecoration: "none",
          };
          ele.value = {
            id: top + "" + left,
            stream: { "api-key": "" },
            tableList: [
              {
                captionName: "数据表",
                tableTitleTr: [
                  { thName: "数据名" },
                  { thName: "最新值" },
                  { thName: "更新时间" },
                ],
                tableTrArr: [],
              },
            ],
          };
          break;
        default:
          return null;
      }
      return ele;
    },

    /* - - - - - - - */

    editCenterStyle() {
      let styleStr = "";

      styleStr += "background:";
      styleStr += this.dynalt.config.canvas[this.canvasCur].background + ";";

      styleStr += !this.RNoption.editEnable ? "cursor: move;" : "";
      styleStr += "top:" + this.board.top + "px;";
      styleStr += "left:" + this.board.left + "px;";
      styleStr += "cursor:" + this.eleBox.cursor + ";";
      styleStr += "width:" + this.dynalt.config.board.width + "px;";
      styleStr += "height:" + this.dynalt.config.board.height + "px;";

      return styleStr;
    },
    elementBoxStyle(box, idx) {
      box.rotate = parseInt(box.rotate);
      let styleStr = "";
      styleStr += "width:" + box.width + "px;";
      styleStr += "height:" + box.height + "px;";
      styleStr += "top:" + box.top + "px;";
      styleStr += "left:" + box.left + "px;";
      styleStr += "z-index:" + idx + ";";
      styleStr += "transform:rotate(" + Math.trunc(box.rotate) + "deg);";
      styleStr += "background:" + box.background + ";";
      return styleStr;
    },
    elementClick(event, idx) { // 点击编辑区组件
      this.resetInpt() // 重置选择的输入框数据
      if (!this.RNoption.editEnable || idx < 0) {
        this.elementCur = -1
        this.resetSelectedElement() // 重置selectedElement
        return
      }
      let isSelectOneElement = true // 是否选中一个未被多选的组件
      if (this.selectedElement.length !== 0) { // 组件没有被多选
        this.selectedElement.forEach(seleItem => {
          if (this.pCanvas.element[idx].id === seleItem.id) { // 选中的是被多选的组件
            isSelectOneElement = false
          }
        })
      }
      if (isSelectOneElement) {
        this.resetSelectedElement() // 重置selectedElement
        this.elementCur = idx;
        this.menu.enable = false;
        switch (event.button) {
          case 0:
            if (this.pElement.box.lock) return;
            this.eleBox = { // 将组件赋值给 eleBox
              enable: true,
              action: "element",
              pointer: this.pElement.box,
              x: this.pElement.box.left,
              y: this.pElement.box.top,
              w: this.pElement.box.width,
              h: this.pElement.box.height,
              r: 0,
            };
            break;
          case 2:
            this.menu.enable = true;
            this.menu.pos = { x: this.mouse.pos.x, y: this.mouse.pos.y };
            this.menu.list[2].enable =
              this.elementCur >= this.pCanvas.element.length - 1 ? false : true;
            this.menu.list[3].enable = this.elementCur < 1 ? false : true;
            this.menu.list[4].enable =
              this.elementCur >= this.pCanvas.element.length - 1 ? false : true;
            this.menu.list[5].enable = this.elementCur < 1 ? false : true;
            for (let item of this.menu.list) {
              item.show = !this.pElement.box.lock;
            }
            this.menu.list[7].show = this.pElement.box.lock;
            break;
        }
      }
    },
    elementStyle(item) {
      let styleStr = "";
      switch (item.type) {
        default:
          for (let idx in item.style) {
            switch (idx) {
              case "fontSize":
                styleStr += idx + ":" + item.style[idx] + "px;";
                break;
              case "lineHeight":
                if (item.style[idx] == "0")
                  styleStr += idx + ":" + item.box.height + "px;";
                else styleStr += idx + ":" + item.style[idx] + ";";
                break;
              default:
                styleStr += idx + ":" + item.style[idx] + ";";
            }
          }
          break;
      }
      return styleStr;
    },
    resizableClick(event, action, cursor) {
      if (this.elementCur < 0 || !this.RNoption.editEnable || event.button != 0)
        return;
      this.eleBox = {
        enable: true,
        action,
        cursor,
        pointer: this.pElement.box,
        x: this.pElement.box.left,
        y: this.pElement.box.top,
        w: this.pElement.box.width,
        h: this.pElement.box.height,
        r: 0,
      };
    },

    menuItemClick(item) {
      if (item.enable == false) return;
      let cacheEle = null;
      switch (item.title) {
        case "复制":
          let ele = lodash.cloneDeep(this.pElement);
          ele.box.top = parseInt(ele.box.top) + 30;
          ele.box.left = parseInt(ele.box.left) + 30;
          this.pCanvas.element.push(ele);
          this.elementCur = this.pCanvas.element.length - 1;
          switch (ele.type) {
            case "active":
              ele.style.id = new Date().getTime();
              break;
          }
          break;
        case "上移一层":
          cacheEle = lodash.cloneDeep(
            this.pCanvas.element[this.elementCur + 1]
          );
          this.$set(
            this.pCanvas.element,
            this.elementCur + 1,
            lodash.cloneDeep(this.pElement)
          );
          this.$set(this.pCanvas.element, this.elementCur, cacheEle);
          this.elementCur += 1;
          break;
        case "下移一层":
          cacheEle = lodash.cloneDeep(
            this.pCanvas.element[this.elementCur - 1]
          );
          this.$set(
            this.pCanvas.element,
            this.elementCur - 1,
            lodash.cloneDeep(this.pElement)
          );
          this.$set(this.pCanvas.element, this.elementCur, cacheEle);
          this.elementCur -= 1;
          break;
        case "置于顶层":
          cacheEle = lodash.cloneDeep(this.pElement);
          this.pCanvas.element.splice(this.elementCur, 1);
          this.pCanvas.element.push(cacheEle);
          this.elementCur = this.pCanvas.element.length - 1;
          break;
        case "置于底层":
          cacheEle = lodash.cloneDeep(this.pElement);
          this.pCanvas.element.splice(this.elementCur, 1);
          this.pCanvas.element.unshift(cacheEle);
          this.elementCur = 0;
          break;
        case "删除":
          this.pCanvas.element.splice(this.elementCur, 1);
          this.elementCur = -1;
          break;
        case "锁定":
          this.pElement.box.lock = true;
          break;
        case "解锁":
          this.pElement.box.lock = false;
          break;
      }
      this.menu.enable = false;
    },

    /* - - - - - - - */

    leftNavClick(event, id, from, config) { // 点击左侧工具栏
      this.resetSelectedElement() // 重置selectedElement
      let p = event.currentTarget.getBoundingClientRect();
      switch (this.head) {
        case "http":
          id = this.urlHandle(id)
          config = this.urlHandle(config)
          break;
      }
      this.drag = { id, from, config };
      this.dragBox = { enable: true, top: p.y, left: p.x, x: p.x, y: p.y };
    },
    labelNavClick(event, labelMsg) { // 点击左侧工具栏数据组件中的标签
      let id = 'data-value'
      const from = 'dataValue'
      let config = {id:'data-value', img: '/img/base_data.e870ad72.svg',title:'数据'}

      this.resetSelectedElement() // 重置selectedElement
      let p = event.currentTarget.getBoundingClientRect();
      switch (this.head) {
        case 'http':
          id = this.urlHandle(id)
          config = this.urlHandle(config)
          break;
      }
      this.drag = { id, from, config };
      p.x = p.x+100
      this.dragBox = { enable: true, top: p.y, left: p.x, x: p.x, y: p.y,
        labelMsg // 如何有labelMsg则属于便捷数据
      }
    },
    getdragBox() {
      return "top:" + this.dragBox.top + "px;left:" + this.dragBox.left + "px";
    },

    /* - - - - - - - */

    funcULClick(index) {
      this.funcULCur = this.funcULCur == index ? -1 : index;
    },
    rightNavSwitch(val) {
      this.rightNavCur = val
      if (this.pElement.value && this.pElement.value.stream) {
        this.selectStreamInput = this.pElement.value.stream.name
      }
      if (this.pElement.value && this.pElement.value.label) {
        this.selectLabelInput = this.pElement.value.label.name
      }
    },
    navHiddenEvent(type) { // 功能栏显示状态改变
      switch(type) {
        case 'left': this.leftNavHidden = !this.leftNavHidden;break
        case 'right': this.rightNavHidden = !this.rightNavHidden;break
      }
    },
    editNavShow(mode) {
      return (
        this.dynalt.config.canvas.length > 1 &&
        this.dynalt.config.nav.float == mode &&
        !this.dynalt.config.nav.enable
      );
    },
    editNavStyle() {
      let styleStr = "";

      styleStr += "background:" + this.dynalt.config.nav.background + ";";

      switch (this.dynalt.config.nav.position) {
        case "top":
          styleStr += "top:0; left:0; width:100%; height:40px;";
          styleStr += "overflow-x:auto; overflow-y:hidden;";
          break;
        case "bottom":
          styleStr += "bottom:0; left:0; width:100%; height:40px;";
          styleStr += "overflow-x:auto; overflow-y:hidden;";
          break;
        case "left":
          styleStr += "top:0; left:0; height:100%;";
          styleStr += "overflow-x:hidden; overflow-y:auto;";
          break;
        case "right":
          styleStr += "top:0; right:0; height:100%;";
          styleStr += "overflow-x:hidden; overflow-y:auto;";
          break;
      }

      return styleStr;
    },
    editNavItemStyle(idx) {
      let styleStr = "";

      styleStr += "color:" + this.dynalt.config.nav.color + ";";
      styleStr +=
        this.canvasCur == idx
          ? "background:" + this.dynalt.config.nav.checkColor + ";"
          : "";
      switch (this.dynalt.config.nav.position) {
        case "top":
        case "bottom":
          styleStr += "display: inline-block;";
          break;
        case "left":
        case "right":
          break;
      }

      return styleStr;
    },

    /* - - - - - - - */

    RNoptionEdit(event, key, val, obj) {
      let p = event.target.getBoundingClientRect();
      switch (key) {
        case "nav-background":
        case "nav-color":
        case "nav-checkColor":
          this.colorEnable(
            this.dynalt.config[key.split("-")[0]],
            key.split("-")[1],
            p.top,
            p.left
          );
          break;
        case "nav-enable":
          this.dynalt.config.nav.enable = !this.dynalt.config.nav.enable;
          break;
        case "canvas":
          if (val == -1) {
            this.dynalt.config.canvas.push({
              name: "画布" + (this.dynalt.config.canvas.length + 1),
              background: "#fff",
              element: [],
            });
            this.canvasCur = this.dynalt.config.canvas.length - 1;
          } else {
            if (event.button == 2) {
              setTimeout(() => {
                this.$confirm(
                  "确定删除 " + this.dynalt.config.canvas[val].name + "？",
                  "提示",
                  {}
                ).then(({ result }) => {
                  if (result) {
                    this.canvasCur =
                      this.canvasCur > this.dynalt.config.canvas.length - 2
                        ? this.dynalt.config.canvas.length - 2
                        : this.canvasCur;
                    this.dynalt.config.canvas.splice(val, 1);
                  }
                });
              }, 150);
            }
          }
          break;
        case "canvas-background":
          let p2 = event.target.getBoundingClientRect();
          this.colorEnable(
            this.dynalt.config[key.split("-")[0]][val],
            key.split("-")[1],
            p2.top,
            p2.left
          );
          break;
        case "style-color":
        case "style-waveColor":
        case "box-background":
          this.colorEnable(
            this.pElement[key.split("-")[0]],
            key.split("-")[1],
            p.top,
            p.left
          );
          break;
        case "style-fontWeight":
        case "style-fontStyle":
        case "style-textDecoration":
        case "box-lock":
          this.pElement[key.split("-")[0]][key.split("-")[1]] = val;
          break;
        case "data-photo":
          if (val == -1) {
            this.pElement.value.list.push({
              way: ">",
              val: 0,
              img: require("../assets/edit/base_photo.svg"),
            });
          } else {
            this.pElement.value.list.splice(val, 1);
          }
          break;
        case "data-text":
          if (val == -1) {
            this.pElement.value.list.push({
              way: ">",
              val: 0,
              cnt: "映射" + (this.pElement.value.list.length + 1) + "文本",
            });
          } else {
            this.pElement.value.list.splice(val, 1);
          }
          break;
        case "chart-curve":
          {
            if (val == -1) {
              this.pElement.value.list.push({
                stream: { 'api-key': '' },
                label: { labelID: '' },
                name: "数据" + (this.pElement.value.list.length + 1),
                linecolor: "#5470C6FF",
                linecolorArea: "#5470c633",
              });
            } else {
              this.pElement.value.list.splice(val, 1);
            }
            this.pElement.value.update = false;
            this.curve_Init(this.pElement);

            let interval = setInterval(() => {
              if (
                document.getElementById(this.pElement.value.echartid) != null
              ) {
                clearInterval(interval);
                this.curve_Update(this.pElement);
              }
            }, 100);
          }
          break;
        case "chart-curve-linecolor":
          this.colorEnable(obj, "linecolor", p.top, p.left);
          break;
        case "chart-curve-linecolorArea":
          this.colorEnable(obj, "linecolorArea", p.top, p.left);
          break;
        case "chart-curve-smooth": {
          if (this.pElement.value.smooth == true) {
            this.pElement.value.smooth = false;
          } else {
            this.pElement.value.smooth = true;
          }

          this.pElement.value.update = false;
          this.curve_Init(this.pElement);

          let interval = setInterval(() => {
            if (document.getElementById(this.pElement.value.echartid) != null) {
              clearInterval(interval);
              this.curve_Update(this.pElement);
            }
          }, 100);
          break;
        }
        case "chart-curve-showSymbol": {
          if (this.pElement.value.showSymbol == true) {
            this.pElement.value.showSymbol = false;
          } else {
            this.pElement.value.showSymbol = true;
          }

          this.pElement.value.update = false;
          this.curve_Init(this.pElement);

          let interval = setInterval(() => {
            if (document.getElementById(this.pElement.value.echartid) != null) {
              clearInterval(interval);
              this.curve_Update(this.pElement);
            }
          }, 100);
          break;
        }
        case "chart-curve-linewidth": {
          this.pElement.value.update = false;
          this.curve_Init(this.pElement);

          let interval = setInterval(() => {
            if (document.getElementById(this.pElement.value.echartid) != null) {
              clearInterval(interval);
              this.curve_Update(this.pElement);
            }
          }, 100);
          break;
        }
        default:
          this.RNoption[key] = val;
      }
    },
    leftNavEdit(event, key) {
      switch (key) {
        case "image":
          let name = event.target.files[0].name;
          setTimeout(() => {
            this.dynaltImageUpload(this.id, name, event.target.files[0]);
          }, 1000);
          break;
      }
    },
    leftNavImageDelete(url) {
      setTimeout(() => {
        this.dragBox.enable = false;
        this.$confirm(
          "确定删除 " + url.split("/")[url.split("/").length - 1] + "？",
          "提示",
          {}
        ).then(({ result }) => {
          this.dragBox.enable = false;
          if (result) {
            this.dynaltImageDelete(this.id, url);
          }
        });
      }, 1);
    },
    leftNavImageLook(url) {
      window.open(url);
    },

    /* - - - - - - - */

    dynaltConfig(id, config) {
      dynalt_Config({ id, config }) // 保存组态图配置信息
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success(res.data.msg);
          } else {
            this.$toast.error("保存失败");
          }
        })
        .catch(this.catchErr);
    },
    dynaltUpdate(data) {
      this.viewLock = false;
      dynalt_Update(data)
        .then(res => {
          if (res.data.code == 200) {
            this.dynaltRetrieve(this.id) // 获取画布全部信息
            this.$toast.success(res.data.msg);
          } else {
            this.$toast.error(res.data.msg);
          }
        })
        .catch(this.catchErr);
    },
    dynaltRetrieve(id) { // 获取画布全部信息
      dynalt_Retrieve(id)
        .then(res => {
          if (res.data.code == 200) {
            this.dynalt = res.data.return.dynalt
            if (this.dynalt.config.canvas == 0) {
              this.dynalt.config.canvas.push({
                name: "画布1（右键删除）",
                background: "#fff",
                element: [],
              });
            } else {
              const fontTemplList = []
              for (let idx in this.dynalt.config.canvas) {
                const canvasIdx = this.dynalt.config.canvas[idx]
                for (let idx2 in canvasIdx.element) {
                  if (canvasIdx.element[idx2].type === 'text_fields') {
                    if (canvasIdx.element[idx2].isSyncTitle) { // 是否同步标题
                      canvasIdx.element[idx2].value = this.dynalt.name
                    }
                    if (canvasIdx.element[idx2].isFontTempl) { // 是否设定为字体模板
                      fontTemplList.push(canvasIdx.element[idx2])
                    }
                  }
                  if (
                    canvasIdx.element[idx2].type ==
                    "active"
                  ) {
                    if (
                      canvasIdx.element[idx2].style.type ==
                      "active-pipe"
                    ) {
                      if (
                        canvasIdx.element[idx2].value !=
                        undefined
                      ) {
                        if (
                          canvasIdx.element[idx2].value[
                          "positive"
                          ] == undefined
                        ) {
                          canvasIdx.element[idx2].value = {
                            cycle: 3,
                            stream: { "api-key": "" },
                            label: { labelID: "" },
                            enable: false,
                            positive: { way: ">=", val: 0 },
                            reverse: { way: "<", val: 0 },
                          };
                        }
                      }
                    }
                  }

                  if (
                    canvasIdx.element[idx2].type ==
                    "data-value"
                  ) {
                    if (
                      canvasIdx.element[idx2].value[
                      "bit"
                      ] == undefined
                    ) {
                      canvasIdx.element[idx2].value[
                        "bit"
                      ] = 2;
                    }
                  }

                  if (
                    canvasIdx.element[idx2].type ==
                    "chart-curve"
                  ) {
                    this.curve_Init(
                      canvasIdx.element[idx2]
                    );
                    let interval = setInterval(() => {
                      if (
                        document.getElementById(
                          canvasIdx.element[idx2].value
                            .echartid
                        ) != null
                      ) {
                        clearInterval(interval);
                        this.curve_Update(
                          canvasIdx.element[idx2]
                        );
                      }
                    }, 100);
                  }
                }
              }
              this.fontTemplList = fontTemplList

              for (let idx in this.dynalt.config.canvas) { // 复制文本模板参数
                const canvasIdx = this.dynalt.config.canvas[idx]
                for (let idx2 in canvasIdx.element) {
                  if (canvasIdx.element[idx2].type === 'text_fields') {
                    fontTemplList.forEach(item => {
                      if (canvasIdx.element[idx2].fontTemplId === item.id) {
                        canvasIdx.element[idx2].box.background = item.box.background
                        canvasIdx.element[idx2].box.height = item.box.height
                        canvasIdx.element[idx2].box.rotate = item.box.rotate
                        canvasIdx.element[idx2].box.width = item.box.width
                        canvasIdx.element[idx2].style = item.style
                      }
                    })
                  }
                }
              }
            }
            this.ok = true;
          } else {
            this.$toast.error("组态不存在");
          }
        })
        .catch(this.catchErr);
    },
    dynaltMaterialPrivate() {
      dynalt_Material_Private()
        .then(res => {
          if (res.data.code == 200) {
            let list = res.data.return.list
            if (list == null) {
              list = []
            }
            this.LNoption.material = setPictureList(this, list)
          }
        })
        .catch(this.catchErr);
    },
    dynaltImageUpload(id, name, file) {
      let param = new FormData();
      param.append("id", id);
      param.append("name", name);
      param.append("file", file);
      dynalt_Image_Upload(param)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success("上传成功");
          } else {
            this.$toast.error("上传失败");
          }
          this.dynaltImageList(id);
        })
        .catch(this.catchErr);
    },
    dynaltImageList(id) {
      this.viewLock = false;
      dynalt_Image_List(id)
        .then(res => {
          if (res.data.code == 200) {
            let list = res.data.return.list
            if (list == null) {
              list = []
            }
            this.LNoption.image = setPictureList(this, list)
            switch (this.head) {
              case "http":
                for (let idx in this.LNoption.image) {
                  this.LNoption.image[idx] = this.urlHandle(this.LNoption.image[idx])
                }
                break;
            }
            
            this.viewLock = true;
          }
        })
        .catch(this.catchErr);
    },
    dynaltImageDelete(id, name) {
      dynalt_Image_Delete(id, name)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success("删除成功");
          } else {
            this.$toast.error("删除失败");
          }
          this.dynaltImageList(id);
        })
        .catch(this.catchErr);
    },
    tolinkStream() {
      tolink_Stream() // 获取数据流列表
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.return.stream == null) {
              res.data.return.stream = [];
            }
            this.streams = res.data.return.stream
            this.streamList = res.data.return.stream
            this.allStream = res.data.return.stream
          }
        })
        .catch(this.catchErr);
    },
    tolinkLabel(apiKey, type) {
      if (apiKey == "") {
        return;
      }
      tolink_Label(apiKey)
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.return.label.list == null) {
              res.data.return.label.list = [];
            }
            this.$set(this.label, apiKey, res.data.return.label.list)
            this.$set(this.label, apiKey + '_input', res.data.return.label.list) // 用户展示筛选的数据列表

            if (type === 'streamLabelData') {
              this.labelList = res.data.return.label.list
              this.allLabelList = res.data.return.label.list
            } else {
              this.setSelectInpt() // 设置选择的输入框数据
            }
          }
        })
        .catch(this.catchErr);
    },
    tolinkRealtime(apiKey, list) {
      tolink_Realtime(apiKey, list)
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.return.labels == null) {
              res.data.return.labels = [];
            }
            for (let index in res.data.return.labels) {
              this.realtime[res.data.return.labels[index]] = {
                value: res.data.return.values[index],
                status: res.data.return.status[index],
                time: res.data.return.times[index],
              };
              this.realtime.update =
                this.realtime.update > 19970801 ? 0 : this.realtime.update + 1;
            }
          }
        })
        .catch("????", this.catchErr);
    },
    tolinkMqttwrite(apiKey, labelID, value, log) {
      tolink_Mqttwrite(apiKey, labelID, value)
        .then(res => {
          if (log) {
            this.control.log.unshift({
              value,
              time: Math.round(new Date().getTime() / 1000),
              msg: res.data.msg,
            });
          } else {
            if (res.data.code == 200) {
              this.$toast.success("下发成功，等待响应");
            } else {
              this.$toast.error(res.data.msg);
            }
          }
        })
        .catch(this.catchErr);
    },
    tolinkGetData(element) {
      if (this.echarts.curve[element.value.echartid]) {
        this.echarts.curve[element.value.echartid].option.dataset[0].source = [];
      }

      let now = Math.round(new Date() / 1000);

      let line_points = [];

      for (let idx in element.value.list) {
        if (
          element.value.list[idx].stream["api-key"] != "" &&
          element.value.list[idx].label["labelID"] != ""
        ) {
          line_points.push({
            linename: element.value.list[idx].name,
            apikey: element.value.list[idx].stream["api-key"],
            labelid: element.value.list[idx].label["labelID"],
          });
        }
      }

      if (line_points.length == 0) {
        return;
      }

      tolink_GetLine(now, element.value.timelength, element.value.pointlength, line_points)
        .then(res => {
          if (res.data.code === 200) {
            this.echarts.curve[
              element.value.echartid
            ].option.dataset[0].source = res.data.return.source;
            this.curve_Update(element);
          }
        })
        .catch((err) => {
          console.log("[dataClearHistory]", err);
        });
    },

    catchErr(err) {
      console.log(err);
      this.$toast.error("网络请求异常");
    },

    /* - - - - - - - */
    setColor() {
      switch (this.pElement.type) {
        case "chart-pie":
          this.pElement.value.pointChartData.labelColor = this.muSlideColor.value.hex;
          break;
        case "chart-curve":
          this.pElement.value.update = false;
          this.curve_Init(this.pElement);
          let interval = setInterval(() => {
            if (document.getElementById(this.pElement.value.echartid) != null) {
              clearInterval(interval);
              this.curve_Update(this.pElement);
            }
          }, 100);
          break;
      }
    },
    colorHandle(act) {
      switch (act) {
        case "input":
          this.muSlideColor.pointer[this.muSlideColor.pkey] =
            this.muSlideColor.value[this.muSlideColor.type];
          break;
        case "check":
          if (this.muSlideColor.isMultipleSelected) { // 属于批量选择
            this.setMultipleColor() // 批量设置颜色
          } else {
            this.setColor()
          }
          this.colorDisable();
          break;
        case "clear":
          this.muSlideColor.pointer[this.muSlideColor.pkey] = this.muSlideColor.cache;
          this.colorDisable();
          break;
      }
    },
    colorEnable(obj, key, top, left) {
      if (top > this.windowHeight / 2) {
        this.muSlideColor.top = top - 348 - 10;
      } else {
        this.muSlideColor.top = top + 40;
      }
      this.muSlideColor.left = left - 110 + 14;
      this.muSlideColor.pointer = obj;
      this.muSlideColor.pkey = key;
      this.muSlideColor.value = obj[key];
      this.muSlideColor.cache = obj[key];
      this.muSlideColor.enable = true;
    },
    colorDisable() {
      this.muSlideColor.top = 0;
      this.muSlideColor.left = 0;
      this.muSlideColor.pointer = {};
      this.muSlideColor.pkey = "";
      this.muSlideColor.cache = "";
      this.muSlideColor.enable = false;
    },
    getDatetime(time) {
      var now = time > 0 ? new Date(time * 1000) : new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hh = now.getHours();
      var mm = now.getMinutes();
      var ss = now.getSeconds();
      var clock = year + "-";
      if (month < 10) clock += "0";
      clock += month + "-";
      if (day < 10) clock += "0";
      clock += day + " ";
      if (hh < 10) clock += "0";
      clock += hh + ":";
      if (mm < 10) clock += "0";
      clock += mm + ":";
      if (ss < 10) clock += "0";
      clock += ss;
      return clock;
    },
    getTime(time) {
      var now = time > 0 ? new Date(time * 1000) : new Date();
      var hh = now.getHours();
      var mm = now.getMinutes();
      var ss = now.getSeconds();
      var clock = "";
      if (hh < 10) clock += "0";
      clock += hh + ":";
      if (mm < 10) clock += "0";
      clock += mm + ":";
      if (ss < 10) clock += "0";
      clock += ss;
      return clock;
    },
    dataShow(data, content, upd, bit) {
      if (typeof data != "object") return "暂无";
      if (data.status <= 0) return "异常";

      switch (content) {
        case "value":
          if (bit != undefined) {
            if (data.value.toString().indexOf(".") != -1) {
              return data.value.toFixed(bit);
            }
          }
          return data.value;
        case "time":
          return this.getDatetime(data.time);
        default:
          return "";
      }
    },
    switchShow(data) {
      if (typeof data != "object" || data.status <= 0) return -1;
      if (data.value == 0) return 0;
      else return 1;
    },
    goBack() {
      let routeUrl = this.$router.resolve({
        path: "/studio",
      });
      window.open(routeUrl.href, "_blank");
    },
    streamDataChange() { // 数据组件中选择的数据流
      this.selectStreamDataInput = this.streamData.name
      this.tolinkLabel(this.streamData['api-key'], 'streamLabelData')
    },
    selectSteamDataInputChange() { // 数据组件中数据流输入框改变
      const showStream = [] // 显示出的数据池列表
      this.allStream.forEach(item => {
        if (item.name.indexOf(this.selectStreamDataInput) !== -1) {
          showStream.push(item)
        }
      })
      this.streamList = showStream
    },
    selectLabelDataInputChange() { // 数据组件中标签输入框改变
      const showLabel = [] // 显示出的标签列表
      this.allLabelList.forEach(item => {
        if (item.name.indexOf(this.selectLabelDataInput) !== -1) {
          showLabel.push(item)
        }
      })
      this.labelList = showLabel
    },
    isSyncTitleChange() { // 文本内容是否同步标题
      if (this.pElement.isSyncTitle) {
        this.pElement.value = this.dynalt.name
      }
    },
    isFontTemplChange() { // 文本字体是否设为模板
      if (this.pElement.isFontTempl) {
        let hasFontTemp = false // 被修改的字体是否已存在于字体模板列表中
        this.fontTemplList.forEach(item => {
          if (this.pElement.id === item.id) {
            hasFontTemp = true
          }
        })
        if (!hasFontTemp) {
          this.fontTemplList.push(this.pElement)
        }
      } else {
        const fontTemplList = []
        this.fontTemplList.forEach(item => {
          if (this.pElement.id !== item.id) {
            fontTemplList.push(item)
          }
        })
        this.fontTemplList = fontTemplList
      }
    },
    fontTemplIdChange() { // 选择字体模板改变时
      if (this.pElement.fontTemplId && this.pElement.fontTemplId !== '') {
        this.fontTemplList.forEach(item => {
          if (this.pElement.fontTemplId === item.id) {
            this.pElement.box.background = item.box.background
            this.pElement.box.height = item.box.height
            this.pElement.box.rotate = item.box.rotate
            this.pElement.box.width = item.box.width
            this.pElement.style = item.style
          }
        })
      } else {
        this.pElement.box = lodash.cloneDeep(this.pElement.box)
        this.pElement.style = lodash.cloneDeep(this.pElement.style)
      }
    },
    dynaltAudioPrivate() { // 获取音频素材列表
      dynalt_Audio_List()
        .then(res => {
          if (res.data.code == 200) {
            let list = res.data.return.list
            if (list == null) {
              list = []
            }
            const audioMsgList = []
            list.forEach(url => {
              const lastIndex = url.lastIndexOf('/') // '/'最后一次出现的位置
              audioMsgList.push({
                url,
                name: url.slice(lastIndex + 1)
              })
            })
            
            this.audioMsgList = audioMsgList
          }
        })
        .catch(this.catchErr);
    }
  },
  filters: {
    textareaHandle(val) {
      return val.replace(/\n/g, '"<br>"');
    }
  }
}
</script>

<style lang="stylus" scoped>
.editCss {
  height: 100%;
  width: 100%;
  user-select: none;
  .pcBodyCss {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: #fff;
    .pcHeadCss {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      text-align: center;
      background-color: #383e41;
      z-index: 19970801;
      .pcHeadBackCss {
        position: relative;
        float: left;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        font-size: 26px;
        background-color: #2f2f2f;
        .mu-icon {
          cursor: pointer;
          margin: 16px;
        }
      }
      .pcInfoCss {
        display: inline-block;
        height: 60px;
        line-height: 60px;
        color: #fff;
        font-size: 16px;
      }
      .pcHeadRightCss {
        position: relative;
        float: right;
        margin-right: 20px;
        .pcHeadIcoCss {
          position: relative;
          float: right;
          height: 60px;
          line-height: 60px;
          padding: 0 7px;
          color: #e0e0e0;
          font-size: 14px;
          user-select: none;
          cursor: pointer;
        }
        .pcHeadIcoCss:hover {
          color: #fff;
        }
        .pcHeadIcoCss:active {
          text-shadow: 2px 2px 4px rgb(0, 0, 0, 0.3);
        }
      }
    }
    .pcLeftCss {
      position: fixed;
      z-index: 19970801;
      top: 60px;
      left: 0;
      bottom: 0;
      width: 60px;
      background-color: #4a5053;
      .leftNavBtnCss {
        position: relative;
        padding-left 10px
        left: 0;
        width: 100%;
        height: 30px;
        background-color: #41474a;
        cursor: pointer;
        transform: translateX(0);
        transition: transform 0.5s;
      }

      .leftNavBtnCss:hover {
        background-color: #5c6164;
      }

      .leftNavBtnCss:active {
        background-color: #4b5153;
      }

      .leftNavBtnCss .mu-icon {
        margin: 3px 6px;
      }
      .pcLeftULCss {
        width: 100%;
        height: calc(100% - 180px);
        padding 0 0 20px 0;
        overflow-x: hidden;
        overflow-y: scroll;
      }
      .pcLeftULCss::-webkit-scrollbar {
        display: none;
      }
      .pcLeftRMCss {
        width: 100%;
        height: 180px;
      }
      .pcLeftULItemCss {
        position: relative;
        display: block;
        width: 100%;
        height: 80px;
        line-height: 24px;
        float: left;
        color: #999ea8;
        text-align: center;
        font-size: 14px;
        padding: 15px 0;
        user-select: none;
        cursor: pointer;
        p {
          height: 24px;
          margin: 0;
        }
      }
      .pcLeftULItemCss>div {
        height: 26px;
      }
      .pcLeftULItemCss:hover {
        color: #fff;
      }
    }
    .leftNavCss {
      position: fixed;
      top: 60px;
      left: 60px;
      width: 240px;
      bottom: 0;
      background-color: #fff;
      z-index: 1001;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
      overflow: hidden;
      transform: translateX(0);
      transition: transform 0.6s;
      .funcItemCss {
        position: absolute;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        .streamLabelCss {
          position relative
          width 100%
          .streamSelectCss {
            position relative
            height: 28px;
            width: 100%;
            background: #fff;
            border: 1px solid #3aa6ff;
            select {
              width: 100%;
              height: 100%;
              padding: 0 5px;
              border: none;
              outline: none;
              margin: none;
              background: none;
            }
            .rightNavSelectInputCss {
              position absolute
              top 0
              left 0
              width 90%
              height 100%
              border none
            }
          }
          .labelSelectCss {
            margin-top 10px
            width 100%
            .labelSelectInputCss {
              width 100%
            }
            .lableListCss {
              margin-top 5px
              width 100%
              height 400px
              overflow-y auto
              color #3aa6ff
              .labelCss {
                margin-bottom 10px
                width 100%
                cursor move
              }
            }
          }
        }
      }
    }
  }
}

.pcConCss {
  position: absolute;
  top: 60px;
  left: 60px;
  right: 0;
  bottom: 0;
}

.pcRightCss {
  position: fixed;
  top: 60px;
  bottom: 0;
  right: 0;
  background-color: #41474a;
  z-index: 19970801;
  transform: translateX(0);
  transition: transform 0.6s;
}

/* - - - - - - - - */


.funcItemCss::-webkit-scrollbar {
  display: none;
}

.funcItemTitleCss {
  display: flex;
  width: 100%;
  height: 40px;
}

.funcItemTitleCss div {
  flex: 1;
  height: 20px;
  border-bottom: 1px solid #333;
}

.funcItemTitleCss span {
  display: inline-block;
  height: 20px;
  margin: 10px;
  color: #333;
  font-size: 12px;
}

.funcItemButtomCss {
  width: 100%;
  height: 30px;
  margin: 5px 0;
}

.funcItemButtomCss label {
  display: inline-block;
  height: 30px;
  background: #2196f3;
  border-radius: 36px;
  padding: 0 16px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}

.funcItemButtomCss label:hover {
  background: #3aa6ff;
}

.funcItemButtomCss label:active {
  background: #3aa6ff;
  box-shadow: 3px 3px 13px rgba(0, 0, 0, 0.4);
}

.leftNavBaseCss {
  float: left;
  width: 110px;
  height: 80px;
  margin: 10px 0;
  border: 1px solid #fff;
  cursor: move;
}

.leftNavBaseCss:hover {
  border: 1px solid #00c4cd;
}

.leftNavBaseCss div {
  height: 50px;
  padding: 5px 0;
  text-align: center;
}

.leftNavBaseCss span {
  display: inline-block;
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #666;
  font-size: 14px;
  text-align: center;
}

.leftNavModuleCss {
  position: relative;
  float: left;
  height: 105px;
  width: 105px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #666;
  text-align: center;
  cursor: move;
}

.leftNavModuleCss div {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 20px;
  color: #fff;
  text-align: center;
}

.leftNavModuleCss img {
  height: 60px;
  margin: 10px 0;
}

.leftNavMaterialCss {
  float: left;
  width: 105px;
  height: 118px;
  line-height: 118px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid transparent;
  cursor: move;
}

.leftNavMaterialCss:hover {
  border: 1px solid #00c4cd;
}

.leftNavMaterialCss img {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border: none;
  vertical-align: middle;
}

.leftNavActiveCss {
  position: relative;
  float: left;
  height: 105px;
  width: 105px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #666;
  text-align: center;
  cursor: move;
}

.leftNavActiveCss div {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 20px;
  color: #fff;
  text-align: center;
}

.leftNavActiveCss img {
  height: 60px;
  margin: 10px 0;
}

.leftNavImageCss {
  position: relative;
  float: left;
  width: 105px;
  height: 138px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid transparent;
  cursor: move;
  overflow: hidden;
}

.leftNavImageCss:hover {
  border: 1px solid #00c4cd;
}

.leftNavImageCss:hover button {
  display: inline-block;
}

.leftNavImageCss div {
  width: 105px;
  height: 118px;
  line-height: 118px;
  line-height: 118px;
}

.leftNavImageCss div img {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border: none;
  vertical-align: middle;
}

.leftNavImageCss span {
  display: inline-block;
  height: 20px;
  width: 100%;
  color: #333;
  font-size: 12px;
  overflow: hidden;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.leftNavImageCss button {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  margin: none;
  border: none;
  outline: none;
  padding: 0;
  background: rgba(173, 67, 67, 0.5);
  cursor: pointer;
}

.leftNavImageCss button:hover {
  background: rgba(173, 67, 67, 0.8);
}

.leftNavImageCss button .mu-icon {
  margin: 2px;
}

/* - - - - - - - - */
.rightNavCss {
  position: absolute;
  top: 40px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.rightNavBtnCss {
  position: relative;
  left: 0;
  width: 239px;
  height: 30px;
  background-color: #41474a;
  cursor: pointer;
  transform: translateX(0);
  transition: transform 0.5s;
}

.rightNavBtnCss:hover {
  background-color: #5c6164;
}

.rightNavBtnCss:active {
  background-color: #4b5153;
}

.rightNavBtnCss .mu-icon {
  margin: 3px 6px;
}

.rightNavHeadCss {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.rightNavHeadA {
  position: relative;
  float: left;
  width: 50%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #999ea8;
  border-bottom: 1px solid #676c75;
  cursor: pointer;
  user-select: none;
}

.rightNavHeadB {
  color: #fff;
  border-bottom: 1px solid #4c84ff;
}

.rightNavBodyCss {
  width: 100%;
  height: calc(100% - 40px - 1px);
  font-size: 12px;
}

.rightNavSeparateCss {
  height: 15px;
  width: 100%;
  border-bottom: 1px solid #4a5053;
  margin-bottom: 15px;
}

.rightNav-list {
  display: inline-block;
  position: relative;
  box-shadow: 4px -4px 6px rgba(0, 0, 0, 0.4);
}

.multipleRightNavCss {
  width 280px
  height calc(100vh - 100px)
  overflow-y: auto;
}

.rightNavItemCss {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 10px 15px 15px 15px;
}

.rightNavTextCss {
  display: block;
  position: relative;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #f5f6fa;
  user-select: none;
}

.rightNav-delete {
  position: absolute;
  right: 0;
  top: 0;

  height: 25px;
  width: 25px;
  margin: none;
  border: none;
  outline: none;
  padding: 0;
  background: rgba(173, 67, 67, 0.5);
  cursor: pointer;
}

.rightNavSpanCss {
  color: #f5f6fa;
}

.rightNavItemListCss {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.rightNavItemListCss .mu-icon {
  margin-top: 4px;
}

.rightNavItemListCss span {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
}

.rightNavInputCss {
  display: inline-block;
  height: 30px;
  width: 100%;
  background: #fff;
  border: 1px solid #000;
  margin-right: 10px;
}

.rightNavInputCss input {
  width: 100%;
  height: 100%;
  padding: 0 5px;
  border: none;
  outline: none;
  margin: none;
  background: none;
}

.rightNavSelectCssInputCss {
  display: inline-block;
  height: 30px;
  width: 100%;
  margin-right: 10px;
  .rightNavSelectCssInputTitleCss {
    color #fff
    margin-right 5px
  }
}

.rightNavSelectCssInputCss select {
  width: 50%;
  height: 100%;
  padding: 0 5px;
  border: none;
  outline: none;
  margin: none;
  margin-right: 10px;
  background: #fff;
  border: 1px solid #fff;
}

.rightNavSelectCssInputCss input {
  display: inline-block;
  height: 30px;
  width: 25%;
  margin-left: 10px;
  border: 1px solid #fff;
}

.rightNavSelectCssInputCss button:hover {
  background: rgba(173, 67, 67, 0.8);
}

.rightNavSelectCssInputCss button .mu-icon {
  margin: 2px;
}

.rightNavButtonCss {
  display: inline-block;
  position: relative;
  top: -2px;
  height: 30px;
}

.rightNavButtonCss .mu-raised-button {
  min-width: 10px;
}

.rightNavButtonCss .mu-button-small {
  height: 28px;
}

.rightNavSelectCss {
  position relative
  height: 28px;
  width: 100%;
  background: #fff;
  border: 1px solid #fff;
  select {
    width: 100%;
    height: 100%;
    padding: 0 5px;
    border: none;
    outline: none;
    margin: none;
    background: none;
  }
  .rightNavSelectInputCss {
    position absolute
    top 0
    left 0
    width 90%
    height 100%
    border none
  }
}
.rightNavItemCss .rightNavCheckboxCss {
  cursor: pointer;
  border: 1px solid #4c84ff;
}

.rightNavBoxCss {
  height: 30px;
  width: 30px;
  text-align: center;
  margin-right: 20px;
  border: 1px solid #41474a;
  cursor: pointer;
}

.rightNavColorboxCss {
  width: 30px;
  height: 30px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
}

.rightNavViewCss {
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: scroll;
}

.rightNavViewCss::-webkit-scrollbar {
  display: none;
}

.rightNavDetailsCss {
  display: flex;
  width: 100%;
  height: 20px;
}

.rightNavDetailsCss>span {
  font-size: 14px;
  color: #f5f6fa;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  padding: 0 10px;
}

.rightNavDetailsCss>div {
  flex: 1;
  height: 50%;
  border-bottom: 1px solid #4c84ff;
}

.rightNavTextareaCss {
  height: 60px;
  width: 210px;
}

.rightNavTextareaCss textarea {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 5px;
  border: 1px solid #000;
  border-right: none;
  overflow-x: hidden;
  outline: none;
  margin: none;
  line-height: 20px;
  resize: none;
}

.rightNavTextareaCss textarea::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.rightNavTextareaCss textarea::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #aaa;
}

.rightNavTextareaCss textarea::-webkit-scrollbar-track {
  border-radius: 0;
  background: #000;
}

.rightNavManyboxCss {
  display: flex;
  height: 25px;
  width: 100%;
}

.rightNavManyboxCss select {
  flex: 1;
  padding: 5px 0;
  margin: none;
  outline: none;
  overflow: visible;
}

.rightNavManyboxCss button {
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1;
  flex: 1;
  margin: none;
  outline: none;
  font-size: 14px;
}

/* - - - - - - - - */
.pcEditCss {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: #f5f6fa;
}

.editNavSideCss {
  position: absolute;
  z-index: 1000;
  background: green;
  text-align: center;
  white-space: nowrap;
}

.editNavSideCss::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.editNavSideCss::-webkit-scrollbar-thumb {
  background: #666;
}

.editNavSideCss::-webkit-scrollbar-track {
  background: #fff;
}

.editNavItemCss {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  user-select: none;
  cursor: pointer;
  font-size 20px
  font-weight bold
}

.editCenterCss {
  position: relative;
  display: inline-block;
  .yZhouFzxCss {
    position absolute
    top 0
    width 1px
    height 100%
    background-color #B2B2B2
    z-index 9999
    .yZhouButCss {
      position absolute
      top -15px
      left -7px
      width 15px
      height 15px
      background-color #B2B2B2
    }
  }
  .xZhouFzxCss {
    position absolute
    left 0
    width 100%
    height 1px
    background-color #B2B2B2
    z-index 9999
    .xZhouButCss {
      position absolute
      left -15px
      top -7px
      width 15px
      height 15px
      background-color #B2B2B2
    }
  }
}

.editCenterGuideCss {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.guideEnableCss {
  background-image: url("../assets/guide.png");
}

.editCenterExpansionCss {
  position: absolute;
  bottom: -60px;
  right: -300px;
  width: 1px;
  height: 1px;
}

.editCenterResizableRotateCss {
  position: absolute;
  left: 50%;
  top: -45px;
  margin-left: -12px;
  width: 24px;
  height: 24px;
  background-image: url("../assets/edit/center_rotate.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  z-index: 2000;
}

.editCenterResizableCss {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 90;
}

.resizableNCss {
  border-top: 1px dashed #383e41;
  width: auto;
  height: 10px;
  left: -9px;
  top: -10px;
  right: -9px;
  cursor: n-resize;
}

.resizableNCss:before {
  content: "";
  left: 50%;
  top: -5px;
  margin-left: -5px;
  position: absolute;
  width: 10px;
  height: 10px;
  border: solid 1px #383e41;
  background-color: #fff;
}

.resizableECss {
  border-right: 1px dashed #383e41;
  width: 10px;
  bottom: -9px;
  right: -10px;
  top: -9px;
  height: auto;
  cursor: e-resize;
}

.resizableECss:before {
  content: "";
  width: 10px;
  height: 10px;
  border: solid 1px #383e41;
  top: 50%;
  right: -5px;
  margin-top: -5px;
  position: absolute;
  background-color: #fff;
}

.resizableSCss {
  border-bottom: 1px dashed #383e41;
  width: auto;
  height: 10px;
  left: -9px;
  bottom: -10px;
  right: -9px;
  cursor: s-resize;
}

.resizableSCss:before {
  content: "";
  width: 10px;
  height: 10px;
  border: solid 1px #383e41;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  position: absolute;
  background-color: #fff;
}

.resizableWCss {
  border-left: 1px dashed #383e41;
  width: 10px;
  height: auto;
  left: -10px;
  top: -9px;
  bottom: -9px;
  cursor: w-resize;
}

.resizableWCss:before {
  content: "";
  width: 10px;
  height: 10px;
  border: solid 1px #383e41;
  left: -5px;
  top: 50%;
  margin-top: -5px;
  position: absolute;
  background-color: #fff;
}

.resizableNeCss {
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border: solid 1px #383e41;
  border-radius: 50%;
  right: -15px;
  top: -15px;
  cursor: ne-resize;
}

.resizableSeCss {
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border: solid 1px #383e41;
  border-radius: 50%;
  right: -15px;
  bottom: -15px;
  cursor: se-resize;
}

.resizableSwCss {
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border: solid 1px #383e41;
  border-radius: 50%;
  left: -15px;
  bottom: -15px;
  cursor: sw-resize;
}

.resizableNwCss {
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border: solid 1px #383e41;
  border-radius: 50%;
  left: -15px;
  top: -15px;
  cursor: nw-resize;
}

.editCenterItemCss {
  position: absolute;
  display: inline-block;
}

.editCenterItemBodyCss {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editCenterBaseCss {
  width: 100%;
  height: 100%;
  white-space: pre;
  overflow: hidden;
}

.editCenterBaseCss,
.editCenterBaseCss * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.editCenterBaseMaskCss {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
}

.elementBorderCss {
  border: 1px dashed #FF9500;
}

.editCenterItemLockCss {
  position: absolute;
  z-index: -1;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: 1px dashed #4c84ff;
}

.editCenterItemLockCss .mu-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  border: 1px solid #666;
  border-radius: 6px;
  background: #fff;
}

/* - - - - - - - - */
.pcColorCss {
  position: absolute;
  z-index: 19970802;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
}

.pcDragCss {
  display: inline-block;
  position: absolute;
  z-index: 19970803;
  left: 50%;
  top: 50%;
}

.pcMenuCss {
  position: fixed;
  width: 150px;
  z-index: 19970804;
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}

.pcMenuItemCss {
  position: relative;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding: 0 10px;
  color: #4a5053;
  cursor: pointer;
}

.pcMenuItemCss:hover {
  background: rgb(76, 132, 255);
  color: #fff;
}

.pcMenuItemCss span {
  font-size: 12px;
  color: #bfc5d1;
  float: right;
}

.pcControlCss {
  position: fixed;
  top: calc(50% - 190px - 60px);
  left: calc(50% - 200px);
  width: 400px;
  height: 380px;
  z-index: 19970804;
  background: #fff;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
}

.pcControlHeadCss {
  width: 100%;
  height: 40px;
  background: #f5f5f5;
  color: #333;
  padding: 0 15px;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pcControlHeadCss span {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.pcControlBodyCss {
  padding: 15px;
}

.pcControlBodyCss .pcControlViewCss {
  width: 100%;
  height: 170px;
  border: 1px solid #ccc;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 20px;
  padding: 5px 0;
}

.pcControlBodyCss .pcControlViewItemCss {
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  font-size: 12px;
}

.pcControlBodyCss textarea {
  display: inline-block;
  width: 100%;
  height: 72px;
  border: 1px solid #ccc;
  padding: 6px 12px;
  outline: none;
  margin: none;
  overflow-x: hidden;
  font-size: 14px;
  color: #555;
}

.pcControlBodyCss textarea::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.pcControlBodyCss textarea::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #000;
}

.pcControlBodyCss textarea::-webkit-scrollbar-track {
  border-radius: 0;
  background: #aaa;
}

.pcControlBtnCss {
  margin-top: 10px;
  height: 30px;
}

.pcControlBtnCss {
  float: right;
  margin-left: 10px;
}

.selectMaskCss {
  position: absolute;
  background: #409eff;
  opacity: 0.4;
  z-index: 100;
  left: 0;
  top: 0;
}

.delectSpanCss {
  margin-left: 10px;
  color: #DDDDDD;
}
</style>